<template>
  <div class="home">
    <nav
      class="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark"
      data-navbar-darken-on-scroll="data-navbar-darken-on-scroll"
    >
      <div class="container">
        <a class="navbar-brand" href="#"
          ><span class="text-white dark__text-white">
            <img src="logo.png" alt="bulla logo" height="32" /> </span></a
        ><button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarStandard"
          aria-controls="navbarStandard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
          <ul
            class="navbar-nav"
            data-top-nav-dropdowns="data-top-nav-dropdowns"
          >
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="landing.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="dashboards"
                >Products</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="dashboards"
              >
                <div class="bg-white dark__bg-1000 rounded-3 py-2">
                  <!-- <a
                    class="dropdown-item link-600 fw-medium"
                    @click="goToLink('notepad')"
                    >Notepad</a
                  > -->
                  <a
                    class="dropdown-item link-600 fw-medium"
                    target="_blank"
                    href="https://demo.bulla.co.ke"
                    >POS & BI Analytics</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="#"
                    >School Management</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="#"
                    >eCommerce<span
                      class="badge rounded-pill ms-2 badge-soft-success"
                      >New</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="#"
                    >Insurance<span
                      class="badge rounded-pill ms-2 badge-soft-success"
                      >New</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="dashboard/project-management.html"
                    >EDMS</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="#"
                    >HRMS</a
                  >
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="landing.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="documentations"
                >Services</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="documentations"
              >
                <div class="bg-white dark__bg-1000 rounded-3 py-2">
                  <a
                    class="dropdown-item link-600 fw-medium"
                    href="documentation/getting-started.html"
                    >Consultancy</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="documentation/customization/configuration.html"
                    >SaaS</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="documentation/customization/styling.html"
                    >Data Center<span
                      class="badge rounded-pill ms-2 badge-soft-success"
                      >Partnership</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="documentation/customization/dark-mode.html"
                    >Dark mode</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="documentation/customization/plugin.html"
                    >Plugins</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="documentation/faq.html"
                    >Open API</a
                  >
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item d-flex align-items-center me-2">
              <div class="nav-link theme-switch-toggle fa-icon-wait p-0">
                <input
                  class="form-check-input ms-0 theme-switch-toggle-input"
                  id="themeControlToggle"
                  type="checkbox"
                  data-theme-control="theme"
                  value="dark"
                /><label
                  class="mb-0 theme-switch-toggle-label theme-switch-toggle-light"
                  for="themeControlToggle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Switch to light theme"
                  ><span class="fas fa-sun"></span></label
                ><label
                  class="mb-0 py-2 theme-switch-toggle-light d-lg-none"
                  for="themeControlToggle"
                  ><span>Switch to light theme</span></label
                ><label
                  class="mb-0 theme-switch-toggle-label theme-switch-toggle-dark"
                  for="themeControlToggle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Switch to dark theme"
                  ><span class="fas fa-moon"></span></label
                ><label
                  class="mb-0 py-2 theme-switch-toggle-dark d-lg-none"
                  for="themeControlToggle"
                  ><span>Switch to dark theme</span></label
                >
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="mailto:join@bulla.co.ke"
                >join@bulla.co.ke |</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="tel:+254727031141"> +254 727 031 141</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div
      class="modal fade"
      id="demoRequestModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div
              class="row text-start justify-content-between align-items-center mb-2"
            >
              <div class="col-auto">
                <h5 id="modalLabel">Choose demo</h5>
              </div>
            </div>
          </div>
          <div class="modal-body p-4">
            <div class="row text-left">
              <div class="col-md-6 mb-3 mb-md-0" href="https://bi.bulla.co.ke">
                <div class="form-check mb-0 custom-radio radio-select">
                  <input
                    class="form-check-input"
                    id="address-1"
                    type="radio"
                    name="clientName"
                    checked="checked"
                    v-model="selected_option"
                    @click="selectedOption(1)"
                  /><label
                    class="form-check-label mb-0 fw-bold d-block"
                    for="address-1"
                    >Insurance BI<span class="radio-select-content"
                      ><span>
                        Insurance Agency,<br />
                        Insurance Company,<br />
                        Insurance Seller,<br />
                        Individaul<br /><br />
                      </span> </span
                  ></label>
                </div>
              </div>
              <div class="col-md-6" href="https://demo.bulla.co.ke">
                <div class="position-relative">
                  <div class="form-check mb-0 custom-radio radio-select">
                    <input
                      class="form-check-input"
                      id="address-2"
                      type="radio"
                      name="clientName"
                      @click="selectedOption(2)"
                      v-model="selected_option"
                    /><label
                      class="form-check-label mb-0 fw-bold d-block"
                      for="address-2"
                      >POS & BI<span class="radio-select-content">
                        Restaurant,<br />
                        Bar/Lounge,<br />
                        Online Store,<br />
                        Chemist & Pharmaceutical,<br /> </span
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-0 overflow-hidden light" id="banner">
      <div
        class="bg-holder overlay"
        style="
          background-image: url('assets/img/generic/bg-3.jpg');
          background-position: center bottom;
        "
      ></div>
      <!--/.bg-holder-->
      <div class="container">
        <div class="row flex-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
          <div
            class="col-md-11 col-lg-8 col-xl-4 pb-7 pb-xl-9 text-center text-xl-start"
          >
            <a
              class="btn btn-outline-danger mb-4 fs--1 border-2 rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#request-demo-modal"
              ><span class="me-2" role="img" aria-label="Gift">🎁</span>Get quotation</a
            >

            <div
              class="modal fade"
              id="request-demo-modal"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
                style="max-width: 600px"
              >
                <div class="modal-content position-relative">
                  <div
                    class="position-absolute top-0 end-0 mt-2 me-2 z-index-1"
                  >
                    <button
                      class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                      <h4 class="mb-1" id="modalExampleDemoLabel">
                        Request quotation
                      </h4>
                    </div>
                    <div class="py-2 px-4 pb-0">
                      <div class="mb-2">
                        <label class="col-form-label" for="recipient-name"
                          >Full Name:</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="email"
                          v-model="formData.name"
                          :class="
                            v$.formData.name.$dirty && v$.formData.name.$invalid
                              ? `is-invalid`
                              : v$.formData.name.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.name.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          Oops! Name is required.
                        </div>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label" for="recipient-name"
                          >Email:</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="email"
                          v-model="formData.email"
                          :class="
                            v$.formData.email.$dirty &&
                            v$.formData.email.$invalid
                              ? `is-invalid`
                              : v$.formData.email.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.email.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          Oops! your email is invalid.
                        </div>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label" for="recipient-name"
                          >Phone:</label
                        >
                        <vue-tel-input
                          @change="phoneUpdated"
                          placeholder="Phone Number"
                          :class="
                            v$.formData.phone.$dirty &&
                            v$.formData.phone.$invalid
                              ? `is-invalid`
                              : v$.formData.phone.$dirty
                              ? `is-valid`
                              : ``
                          "
                        ></vue-tel-input>
                        <div
                          v-for="error of v$.formData.phone.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          Oops! your phone is invalid.
                        </div>
                      </div>
                      <div class="mb-2">
                        <label class="col-form-label" for="message-text"
                          >Describe your business</label
                        >
                        <input
                          class="form-control"
                          id="message-description"
                          type="text"
                          v-model="formData.description"
                          :class="
                            v$.formData.description.$dirty &&
                            v$.formData.description.$invalid
                              ? `is-invalid`
                              : v$.formData.description.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.description.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          Oops! business description is invalid.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-secondary"
                      type="button"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      class="btn btn-warning"
                      type="button"
                      @click="saveContact"
                    >
                      <i class="fa fa-paper-plane"></i>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>

            
            <h4 class="text-white fw-light">
              We help
              <span
                class="typed-text fw-bold ml-10"
                data-typed-text='["small", "medium"]'
              ></span>
              <br />
              business owners succeed
            </h4>
            <p class="mt-3 text-white opacity-75">
              We give service-driven business owners the automation they need to
              operate and grow their business from anywhere in the world.
            </p>
            <!-- <a
              class="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2"
              data-bs-toggle="modal"
              data-bs-target="#demoRequestModal"
              >Demo
              <span
                class="fas fa-play ms-2"
                data-fa-transform="shrink-6 down-1"
              ></span
            ></a> -->
          </div>
          <div class="col-xl-7 offset-xl- align-self-end mt-xl-0">
            <a class="img-landing-banner rounded" href="index.html"
              ><img
                class="img-fluid"
                src="assets/img/generic/dashboard-alt.jpg"
                alt=""
            /></a>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->

    <!-- <section> begin ============================-->
    <section class="py-3 bg-light shadow-sm">
      <div class="container">
        <div class="row flex-center">
          <a
            href="https://swypepay.io/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
          >
            <img
              class="landing-cta-img grayscale"
              height="78"
              src="assets/img/logos/b&amp;w/swypepay.png"
              alt=""
            />
          </a>
          <a
            href="https://www.jia.co.ke/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
          >
            <img
              class="landing-cta-img grayscale"
              height="32"
              src="assets/img/logos/b&amp;w/jia.png"
              alt=""
            />
          </a>
          <a
            href="https://www.terrasoftworks.co.ke/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
          >
            <img
              class="landing-cta-img grayscale"
              height="68"
              src="assets/img/logos/b&amp;w/terra.webp"
              alt=""
            />
          </a>
          <a
            href="https://www.softwareske.com/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
            ><img
              class="landing-cta-img grayscale"
              height="48"
              src="assets/img/logos/b&amp;w/SoftwaresKe.png"
              alt=""
          /></a>
          <a
            href="https://halfpricedbooks.co.ke/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
            ><img
              class="landing-cta-img grayscale"
              height="35"
              src="assets/img/logos/b&amp;w/halfpricedbooks.webp"
              alt=""
          />
        </a>
          <a
            href="https://tadeo.co.ke/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
          >
            <img
              class="landing-cta-img grayscale"
              height="30"
              src="assets/img/logos/b&amp;w/tadeo.png"
              alt=""
            />
          </a>
          <a
            href="https://advantasms.com/"
            class="col-3 col-sm-auto my-1 my-sm-3 px-card"
            ><img
              class="landing-cta-img grayscale"
              height="30"
              src="assets/img/logos/b&amp;w/advanta-africa.png"
              alt=""
          /></a>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section>
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-8 col-xl-7 col-xxl-6">
            <h1 class="fs-2 fs-sm-4 fs-md-5">We build for the future</h1>
            <p class="lead">
              Carefuly crafted features for your business to manage your
              operations, orders, sales, expenses, inventory, employees, &
              strategic plans from all channels in one place.
            </p>
          </div>
        </div>
        <div class="row flex-center mt-8">
          <div class="col-md col-lg-5 col-xl-4 ps-lg-6">
            <img
              class="img-fluid px-6 px-md-0"
              src="assets/img/icons/spot-illustrations/quote.png"
              alt=""
              width="250px"
            />
          </div>
          <div class="col-md col-lg-5 col-xl-4 mt-4 mt-md-0">
            <h5 class="text-danger">
              <span class="far fa-lightbulb me-2"></span>Bulla BI System
            </h5>
            <h3>Insurance Agency</h3>
            <p>
              With Bulla BI, you can now generate quotations for different
              premium providers within 3 minutes,send to prospect and make your
              sale a reality.
            </p>
          </div>
        </div>
        <div class="row flex-center mt-7">
          <div class="col-md col-lg-5 col-xl-4 pe-lg-6 order-md-2">
            <img
              class="img-fluid px-6 px-md-0"
              src="assets/img/icons/spot-illustrations/ecommerce.png"
              alt=""
            />
          </div>
          <div class="col-md col-lg-5 col-xl-4 mt-4 mt-md-0">
            <h5 class="text-info">
              <span class="far fa-object-ungroup me-2"></span>POS & eCommerce
            </h5>
            <h3>Online Store</h3>
            <p>
              Build your online store effortlessly with Bulla's robust set of
              solutions, we offer you a robust POS system to ower your eCommerce
              website to recieve orders from anywhere. MPESA and Card payments
              integrated.
            </p>
          </div>
        </div>
        <div class="row flex-center mt-7">
          <div class="col-md col-lg-5 col-xl-4 ps-lg-6">
            <img
              class="img-fluid px-6 px-md-0"
              src="assets/img/icons/spot-illustrations/analytics.png"
              alt=""
            />
          </div>
          <div class="col-md col-lg-5 col-xl-4 mt-4 mt-md-0">
            <h5 class="text-success">
              <span class="far fa-paper-plane me-2"></span>Bulla Analytics
            </h5>
            <h3>Let your data make sense</h3>
            <p>
              Bulla Business Intelligence (BI) solutions serve to retrieve,
              process, analyze and report data for making informed business
              decisions.
            </p>
            <p>
              We offer robust dashboard for data visualization and analytics
              that your business needs to track KPIs and other business metrics.
            </p>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-light text-center">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1 class="fs-2 fs-sm-4 fs-md-5">Here's what's in it for you</h1>
            <p class="lead">
              Things you will get right out of the box with Bulla.
            </p>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-lg-4">
            <div class="card card-span h-100">
              <div class="card-span-img">
                <span class="fa fa-headset fs-4 text-primary"></span>
              </div>
              <div class="card-body pt-6 pb-4">
                <h5 class="mb-2">Free Suport 24/7</h5>
                <p>
                  We offer professional live suport to our clients round the
                  clock.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-6 mt-lg-0">
            <div class="card card-span h-100">
              <div class="card-span-img">
                <span class="fa fa-mobile fs-5 text-warning"></span>
              </div>
              <div class="card-body pt-6 pb-4">
                <h5 class="mb-2">Responsive Design</h5>
                <p>Get a design that works in all your devices.</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-6 mt-lg-0">
            <div class="card card-span h-100">
              <div class="card-span-img">
                <span class="fa fa-users fs-6 text-info"></span>
              </div>
              <div class="card-body pt-6 pb-4">
                <h5 class="mb-2">User Oriented</h5>
                <p>
                  Over a decade experience in user friendly and easy to use
                  design.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-200 text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-xl-8">
            <div
              class="swiper-container theme-slider"
              data-swiper='{"autoplay":true,"spaceBetween":5,"loop":true,"loopedSlides":5,"slideToClickedSlide":true}'
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="px-5 px-sm-6">
                    <p class="fs-sm-1 fs-md-2 fst-italic text-dark">
                      We&apos;ve become fanboys! Easy to customize the modular
                      design, great analytics dashboard, enterprise-class
                      support, fast loading time. What else do you want from a
                      Bulla Softwares?
                    </p>
                    <p class="fs-0 text-600">
                      - Washington Okungu, GENERAL MANAGER
                    </p>
                    <p class="text-900 h4">Tadeo Insurance Agency, Nairobi</p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="px-5 px-sm-6">
                    <p class="fs-sm-1 fs-md-2 fst-italic text-dark">
                      Bulla is the best option if you are looking for a software
                      patner for business. On top of that, Bulla&apos;s creators
                      and support staff are very brilliant and attentive to
                      users&apos; needs?
                    </p>
                    <p class="fs-0 text-600">
                      - Edith Kabaki, Business Executive
                    </p>
                    <p class="text-900 h4">JIA Insurance Agency Ltd</p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="px-5 px-sm-6">
                    <p class="fs-sm-1 fs-md-2 fst-italic text-dark">
                      When I first saw Bulla, I was totally amazed by the way
                      their software design looks. It felt like something that
                      I&apos;d really want to use and something I could see
                      being a true modern replacement to the current class of
                      Point of sale system.
                    </p>
                    <p class="fs-0 text-600">- Nicholas Muthoka, Manager</p>
                    <p class="text-900 h4">
                      Swiss Hibernation Resort, Machakos
                    </p>
                  </div>
                </div>
              </div>
              <div class="swiper-nav">
                <div class="swiper-button-next swiper-button-white"></div>
                <div class="swiper-button-prev swiper-button-white"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="light bg-dark">
      <div
        class="bg-holder overlay"
        style="
          background-image: url('assets/img/generic/bg-2.jpg');
          background-position: center top;
        "
      ></div>
      <!--/.bg-holder-->
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-8">
            <p class="fs-3 fs-sm-4 text-white">
              Join our community of 20+ businesses and individuals on their
              mission to build better software experience for their operations.
            </p>
            <button
              class="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2"
              type="button"
              @click="startSubscription"
            >
              Start your subscription
            </button>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="bg-dark pt-8 pb-4 light">
      <div class="container">
        <div class="position-absolute btn-back-to-top bg-dark">
          <a class="text-600" href="landing.html#banner" data-bs-offset-top="0"
            ><span
              class="fas fa-chevron-up"
              data-fa-transform="rotate-45"
            ></span
          ></a>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <h5 class="text-uppercase text-white opacity-85 mb-3">
              Our Mission
            </h5>
            <p class="text-600">
              To help service-driven, small business owners succeed.
            </p>
            <div class="icon-group mt-2">
              Statistics show that one out of five small businesses fails within
              the first year. Why? Because most business owners lack the proper
              business foundation. They burn out before they succeed. Bulla is
              dedicated to providing the automation that small business owners
              need to succeed.
            </div>
          </div>
          <div class="col-lg-4">
            <iframe class="d-none d-md-flex float-right" width="560" height="315" src="https://www.youtube.com/embed/i6N-KVEbzjI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe class="d-flex d-md-none float-right" width="350" height="150" src="https://www.youtube.com/embed/i6N-KVEbzjI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!-- <div class="row mt-5 mt-lg-0">
              <div class="col-6 col-md-3">
                <h5 class="text-uppercase text-white opacity-85 mb-3">Company</h5>
                <ul class="list-unstyled">
                  <li class="mb-1"><a class="link-600" href="landing.html#!">About</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Contact</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Careers</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Blog</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Terms</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Privacy</a></li>
                  <li><a class="link-600" href="landing.html#!">Imprint</a></li>
                </ul>
              </div>
              <div class="col-6 col-md-3">
                <h5 class="text-uppercase text-white opacity-85 mb-3">Product</h5>
                <ul class="list-unstyled">
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Features</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Roadmap</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Changelog</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Pricing</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Docs</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">System Status</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Agencies</a></li>
                  <li class="mb-1"><a class="link-600" href="landing.html#!">Enterprise</a></li>
                </ul>
              </div>
              <div class="col mt-5 mt-md-0">
                <h5 class="text-uppercase text-white opacity-85 mb-3">From the Blog</h5>
                <ul class="list-unstyled">
                  <li>
                    <h5 class="fs-0 mb-0"><a class="link-600" href="landing.html#!"> Interactive graphs and charts</a>
                    </h5>
                    <p class="text-600 opacity-50">Jan 15 &bull; 8min read </p>
                  </li>
                  <li>
                    <h5 class="fs-0 mb-0"><a class="link-600" href="landing.html#!"> Lifetime free updates</a></h5>
                    <p class="text-600 opacity-50">Jan 5 &bull; 3min read &starf;</p>
                  </li>
                  <li>
                    <h5 class="fs-0 mb-0"><a class="link-600" href="landing.html#!"> Merry Christmas From us</a></h5>
                    <p class="text-600 opacity-50">Dec 25 &bull; 2min read</p>
                  </li>
                  <li>
                    <h5 class="fs-0 mb-0"><a class="link-600" href="landing.html#!"> The New Falcon Theme</a></h5>
                    <p class="text-600 opacity-50">Dec 23 &bull; 10min read </p>
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <!-- ============================================-->
    <!-- <section> begin ============================-->
    <section class="py-0 bg-dark light">
      <div>
        <hr class="my-0 text-600 opacity-25" />
        <div class="container py-3">
          <div class="row justify-content-between fs--1">
            <div class="col-12 col-sm-auto text-center">
              <p class="mb-0 text-600 opacity-85">
                Thank you for visiting
                <span class="d-none d-sm-inline-block">| </span
                ><br class="d-sm-none" />
                2022 &copy;
                <a class="text-white opacity-85" href="https://bulla.co.ke"
                  >Bulla Softworks Ltd</a
                >
              </p>
            </div>
            <div class="col-12 col-sm-auto text-center">
              <p class="mb-0 text-600 opacity-85">v3.13.0</p>
            </div>
          </div>
        </div>
      </div>
      <!-- end of .container-->
    </section>
    <!-- <section> close ============================-->
    <!-- ============================================-->

    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="landing.html#!">terms </a>and
                  <a href="landing.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="landing.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="landing.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
img.grayscale {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
    -webkit-transition: all .6s ease; /* Fade to color for Chrome and Safari */
    -webkit-backface-visibility: hidden; /* Fix for transition flickering */
}

img.grayscale:hover {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(0%);
}
</style>

<script>
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import ContactService from "../services/contact.service";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import { useCookies } from "vue3-cookies";

export default {
  name: "HomeView",
  components: {},
  setup() {
    const v$ = useVuelidate();
    const { cookies } = useCookies();
    return { v$, cookies };
  },
  created() {
    this.forceReload();
  },
  mounted() {
    let my_cookie_value = this.cookies.get("myCoookie");
    console.log(my_cookie_value);
    this.cookies.set("myCoookie", "abcdefg");
  },
  data() {
    return {
      formData: {},
      email_or_phone: "",
      selected_option: 1,
    };
  },
  validations() {
    return {
      formData: {
        email: { required, email },
        phone: { required },
        name: { required },
        description: { required },
      },
    };
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    forceReload() {
      if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
        this.$store.commit("SET_LOADING", false);
      } else {
        this.$store.commit("SET_LOADING", false);
        localStorage.setItem("reloaded", "1");
        this.$router.go();
      }
    },
    selectedOption(option) {
      if (option == 1) {
        $("#demoRequestModal").hide();
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        window.open("https://ins.bulla.co.ke/", "_blank");
      } else {
        $("#demoRequestModal").hide();
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        window.open("https://demo.bulla.co.ke/", "_blank");
      }
    },
    startSubscription() {
      this.$router.push("/get-started");
    },
    phoneUpdated(e) {
      this.v$.formData.phone.$touch();
      console.log(e.target.value);
      this.formData.phone = e.target.value;
    },
    saveContact() {
      this.v$.formData.email.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.name.$touch();
      this.v$.formData.description.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.formData.note = this.formData.description;
      this.formData.mobile_no = this.formData.phone;
      this.formData.first_name = this.formData.name.split(" ")[0];
      this.formData.last_name = this.formData.name;
      this.$store.commit("SET_LOADING", true);
      ContactService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            // Clear modals
            $("#request-demo-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            toast.success("Your details have been saved!");
          } else {
            console.log(response.data.error);
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.success(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
