import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInput from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

const VueTelInputOptions = {
  mode: "international",
  placeholder: "Phone Number *",
  onlyCountries: ["KE", "UG", "TZ"],
};

createApp(App)
  .use(store)
  .use(VueTelInput, VueTelInputOptions)
  .use(router)
  .mount("#app");
