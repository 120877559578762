import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from '../views/HomeView.vue'
import GetStartedView from '../views/GetStartedView.vue'
import NotepadView from '../views/NotepadView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/get-started',
    name: 'get-started',
    component: GetStartedView
  },
  {
    path: '/notepad',
    name: 'notepad',
    component: NotepadView
  }
]

const router = createRouter({
  // mode: "history",
  mode: "hash",
  linkActiveClass: "active",
  base: process.env.VUE_APP_ROOT_URL,
  history: createWebHashHistory(process.env.VUE_APP_ROOT_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

export default router
