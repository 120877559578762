import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class ContactService {
  store(formData) {
    return axios.post(VUE_APP_BASE_URL + "customer", formData, {
      headers: authHeader(),
    });
  }
}

export default new ContactService();
