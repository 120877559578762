<template>
  <div class="container" data-layout="container">
    <nav
      class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg"
      data-double-top-nav="data-double-top-nav"
      style="display: none"
    >
      <div class="w-100">
        <div class="d-flex flex-between-center">
          <button
            class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarDoubleTop"
            aria-controls="navbarDoubleTop"
            aria-expanded="false"
            aria-label="Toggle Navigation"
          >
            <span class="navbar-toggle-icon"
              ><span class="toggle-line"></span
            ></span>
          </button>
          <a class="navbar-brand me-1 me-sm-3" href="../../index.html">
            <div class="d-flex align-items-center">
              <img
                class="me-2"
                src="assets/img/icons/spot-illustrations/falcon.png"
                alt=""
                width="40"
              /><span class="font-sans-serif">falcon</span>
            </div>
          </a>
          <ul class="navbar-nav align-items-center d-none d-lg-block">
            <li class="nav-item">
              <div class="search-box" data-list='{"valueNames":["title"]}'>
                <form
                  class="position-relative"
                  data-bs-toggle="search"
                  data-bs-display="static"
                >
                  <input
                    class="form-control search-input fuzzy-search"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
                <div
                  class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                  data-bs-dismiss="search"
                >
                  <button
                    class="btn btn-link btn-close-falcon p-0"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100"
                >
                  <div class="scrollbar list py-3" style="max-height: 24rem">
                    <h6
                      class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                    >
                      Recently Browsed
                    </h6>
                    <a
                      class="dropdown-item fs--1 px-card py-1 hover-primary"
                      href="../events/event-detail.html"
                    >
                      <div class="d-flex align-items-center">
                        <span class="fas fa-circle me-2 text-300 fs--2"></span>
                        <div class="fw-normal title">
                          Pages
                          <span
                            class="fas fa-chevron-right mx-1 text-500 fs--2"
                            data-fa-transform="shrink-2"
                          ></span>
                          Events
                        </div>
                      </div>
                    </a>
                    <a
                      class="dropdown-item fs--1 px-card py-1 hover-primary"
                      href="customers.html"
                    >
                      <div class="d-flex align-items-center">
                        <span class="fas fa-circle me-2 text-300 fs--2"></span>
                        <div class="fw-normal title">
                          E-commerce
                          <span
                            class="fas fa-chevron-right mx-1 text-500 fs--2"
                            data-fa-transform="shrink-2"
                          ></span>
                          Customers
                        </div>
                      </div>
                    </a>
                    <hr class="text-200 dark__text-900" />
                    <h6
                      class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                    >
                      Suggested Filter
                    </h6>
                    <a
                      class="dropdown-item px-card py-1 fs-0"
                      href="customers.html"
                    >
                      <div class="d-flex align-items-center">
                        <span
                          class="badge fw-medium text-decoration-none me-2 badge-soft-warning"
                          >customers:</span
                        >
                        <div class="flex-1 fs--1 title">All customers list</div>
                      </div>
                    </a>
                    <a
                      class="dropdown-item px-card py-1 fs-0"
                      href="../events/event-detail.html"
                    >
                      <div class="d-flex align-items-center">
                        <span
                          class="badge fw-medium text-decoration-none me-2 badge-soft-success"
                          >events:</span
                        >
                        <div class="flex-1 fs--1 title">
                          Latest events in current month
                        </div>
                      </div>
                    </a>
                    <a
                      class="dropdown-item px-card py-1 fs-0"
                      href="product/product-grid.html"
                    >
                      <div class="d-flex align-items-center">
                        <span
                          class="badge fw-medium text-decoration-none me-2 badge-soft-info"
                          >products:</span
                        >
                        <div class="flex-1 fs--1 title">
                          Most popular products
                        </div>
                      </div>
                    </a>
                    <hr class="text-200 dark__text-900" />
                    <h6
                      class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                    >
                      Files
                    </h6>
                    <a class="dropdown-item px-card py-2" href="invoice.html#!">
                      <div class="d-flex align-items-center">
                        <div class="file-thumbnail me-2">
                          <img
                            class="border h-100 w-100 fit-cover rounded-3"
                            src="assets/img/products/3-thumb.png"
                            alt=""
                          />
                        </div>
                        <div class="flex-1">
                          <h6 class="mb-0 title">iPhone</h6>
                          <p class="fs--2 mb-0 d-flex">
                            <span class="fw-semi-bold">Antony</span
                            ><span class="fw-medium text-600 ms-2"
                              >27 Sep at 10:30 AM</span
                            >
                          </p>
                        </div>
                      </div>
                    </a>
                    <a class="dropdown-item px-card py-2" href="invoice.html#!">
                      <div class="d-flex align-items-center">
                        <div class="file-thumbnail me-2">
                          <img
                            class="img-fluid"
                            src="assets/img/icons/zip.png"
                            alt=""
                          />
                        </div>
                        <div class="flex-1">
                          <h6 class="mb-0 title">Falcon v1.8.2</h6>
                          <p class="fs--2 mb-0 d-flex">
                            <span class="fw-semi-bold">John</span
                            ><span class="fw-medium text-600 ms-2"
                              >30 Sep at 12:30 PM</span
                            >
                          </p>
                        </div>
                      </div>
                    </a>
                    <hr class="text-200 dark__text-900" />
                    <h6
                      class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                    >
                      Members
                    </h6>
                    <a
                      class="dropdown-item px-card py-2"
                      href="../../pages/user/profile.html"
                    >
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-l status-online me-2">
                          <img
                            class="rounded-circle"
                            src="assets/img/team/1.jpg"
                            alt=""
                          />
                        </div>
                        <div class="flex-1">
                          <h6 class="mb-0 title">Anna Karinina</h6>
                          <p class="fs--2 mb-0 d-flex">Technext Limited</p>
                        </div>
                      </div>
                    </a>
                    <a
                      class="dropdown-item px-card py-2"
                      href="../../pages/user/profile.html"
                    >
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-l me-2">
                          <img
                            class="rounded-circle"
                            src="assets/img/team/2.jpg"
                            alt=""
                          />
                        </div>
                        <div class="flex-1">
                          <h6 class="mb-0 title">Antony Hopkins</h6>
                          <p class="fs--2 mb-0 d-flex">Brain Trust</p>
                        </div>
                      </div>
                    </a>
                    <a
                      class="dropdown-item px-card py-2"
                      href="../../pages/user/profile.html"
                    >
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-l me-2">
                          <img
                            class="rounded-circle"
                            src="assets/img/team/3.jpg"
                            alt=""
                          />
                        </div>
                        <div class="flex-1">
                          <h6 class="mb-0 title">Emma Watson</h6>
                          <p class="fs--2 mb-0 d-flex">Google</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="text-center mt-n3">
                    <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul
            class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center"
          >
            <li class="nav-item">
              <div class="theme-control-toggle fa-icon-wait px-2">
                <input
                  class="form-check-input ms-0 theme-control-toggle-input"
                  id="themeControlToggle"
                  type="checkbox"
                  data-theme-control="theme"
                  value="dark"
                /><label
                  class="mb-0 theme-control-toggle-label theme-control-toggle-light"
                  for="themeControlToggle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Switch to light theme"
                  ><span class="fas fa-sun fs-0"></span></label
                ><label
                  class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                  for="themeControlToggle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Switch to dark theme"
                  ><span class="fas fa-moon fs-0"></span
                ></label>
              </div>
            </li>
            <li class="nav-item d-none d-sm-block">
              <a
                class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait"
                href="shopping-cart.html"
                ><span
                  class="fas fa-shopping-cart"
                  data-fa-transform="shrink-7"
                  style="font-size: 33px"
                ></span
                ><span class="notification-indicator-number">1</span></a
              >
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
                id="navbarDropdownNotification"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-hide-on-body-scroll="data-hide-on-body-scroll"
                ><span
                  class="fas fa-bell"
                  data-fa-transform="shrink-6"
                  style="font-size: 33px"
                ></span
              ></a>
              <div
                class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                aria-labelledby="navbarDropdownNotification"
              >
                <div class="card card-notification shadow-none">
                  <div class="card-header">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-auto">
                        <h6 class="card-header-title mb-0">Notifications</h6>
                      </div>
                      <div class="col-auto ps-0 ps-sm-3">
                        <a class="card-link fw-normal" href="invoice.html#"
                          >Mark all as read</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="scrollbar-overlay" style="max-height: 19rem">
                    <div class="list-group list-group-flush fw-normal fs--1">
                      <div class="list-group-title border-bottom">NEW</div>
                      <div class="list-group-item">
                        <a
                          class="notification notification-flush notification-unread"
                          href="invoice.html#!"
                        >
                          <div class="notification-avatar">
                            <div class="avatar avatar-2xl me-3">
                              <img
                                class="rounded-circle"
                                src="assets/img/team/1-thumb.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1">
                              <strong>Emma Watson</strong> replied to your
                              comment : "Hello world 😍"
                            </p>
                            <span class="notification-time"
                              ><span class="me-2" role="img" aria-label="Emoji"
                                >💬</span
                              >Just now</span
                            >
                          </div>
                        </a>
                      </div>
                      <div class="list-group-item">
                        <a
                          class="notification notification-flush notification-unread"
                          href="invoice.html#!"
                        >
                          <div class="notification-avatar">
                            <div class="avatar avatar-2xl me-3">
                              <div class="avatar-name rounded-circle">
                                <span>AB</span>
                              </div>
                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1">
                              <strong>Albert Brooks</strong> reacted to
                              <strong>Mia Khalifa's</strong> status
                            </p>
                            <span class="notification-time"
                              ><span
                                class="me-2 fab fa-gratipay text-danger"
                              ></span
                              >9hr</span
                            >
                          </div>
                        </a>
                      </div>
                      <div class="list-group-title border-bottom">EARLIER</div>
                      <div class="list-group-item">
                        <a
                          class="notification notification-flush"
                          href="invoice.html#!"
                        >
                          <div class="notification-avatar">
                            <div class="avatar avatar-2xl me-3">
                              <img
                                class="rounded-circle"
                                src="assets/img/icons/weather-sm.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1">
                              The forecast today shows a low of 20&#8451; in
                              California. See today's weather.
                            </p>
                            <span class="notification-time"
                              ><span class="me-2" role="img" aria-label="Emoji"
                                >🌤️</span
                              >1d</span
                            >
                          </div>
                        </a>
                      </div>
                      <div class="list-group-item">
                        <a
                          class="border-bottom-0 notification-unread notification notification-flush"
                          href="invoice.html#!"
                        >
                          <div class="notification-avatar">
                            <div class="avatar avatar-xl me-3">
                              <img
                                class="rounded-circle"
                                src="assets/img/logos/oxford.png"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1">
                              <strong>University of Oxford</strong> created an
                              event : "Causal Inference Hilary 2019"
                            </p>
                            <span class="notification-time"
                              ><span class="me-2" role="img" aria-label="Emoji"
                                >✌️</span
                              >1w</span
                            >
                          </div>
                        </a>
                      </div>
                      <div class="list-group-item">
                        <a
                          class="border-bottom-0 notification notification-flush"
                          href="invoice.html#!"
                        >
                          <div class="notification-avatar">
                            <div class="avatar avatar-xl me-3">
                              <img
                                class="rounded-circle"
                                src="assets/img/team/10.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1">
                              <strong>James Cameron</strong> invited to join the
                              group: United Nations International Children's
                              Fund
                            </p>
                            <span class="notification-time"
                              ><span class="me-2" role="img" aria-label="Emoji"
                                >🙋‍</span
                              >2d</span
                            >
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-center border-top">
                    <a
                      class="card-link d-block"
                      href="../social/notifications.html"
                      >View all</a
                    >
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown px-1">
              <a
                class="nav-link fa-icon-wait nine-dots p-1"
                id="navbarDropdownMenu"
                role="button"
                data-hide-on-body-scroll="data-hide-on-body-scroll"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="43"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
                  <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
                  <circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
                  <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
                  <circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
                  <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
                  <circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
                  <circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
                  <circle cx="14" cy="2" r="2" fill="#6C6E71"></circle></svg
              ></a>
              <div
                class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-caret-bg"
                aria-labelledby="navbarDropdownMenu"
              >
                <div class="card shadow-none">
                  <div class="scrollbar-overlay nine-dots-dropdown">
                    <div class="card-body px-3">
                      <div class="row text-center gx-0 gy-0">
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="../../pages/user/profile.html"
                            target="_blank"
                          >
                            <div class="avatar avatar-2xl">
                              <img
                                class="rounded-circle"
                                src="assets/img/team/3.jpg"
                                alt=""
                              />
                            </div>
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2"
                            >
                              Account
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="https://themewagon.com/"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/themewagon.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Themewagon
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="https://mailbluster.com/"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/mailbluster.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Mailbluster
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/google.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Google
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/spotify.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Spotify
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/steam.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Steam
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/github-light.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Github
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/discord.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Discord
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/xbox.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              xbox
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/trello.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Kanban
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/hp.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Hp
                            </p>
                          </a>
                        </div>
                        <div class="col-12">
                          <hr class="my-3 mx-n3 bg-200" />
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/linkedin.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Linkedin
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/twitter.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Twitter
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/facebook.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Facebook
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/instagram.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Instagram
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/pinterest.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Pinterest
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/slack.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Slack
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="invoice.html#!"
                            target="_blank"
                            ><img
                              class="rounded"
                              src="assets/img/nav-icons/deviantart.png"
                              alt=""
                              width="40"
                              height="40"
                            />
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                            >
                              Deviantart
                            </p>
                          </a>
                        </div>
                        <div class="col-4">
                          <a
                            class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                            href="../events/event-detail.html"
                            target="_blank"
                          >
                            <div class="avatar avatar-2xl">
                              <div
                                class="avatar-name rounded-circle bg-soft-primary text-primary"
                              >
                                <span class="fs-2">E</span>
                              </div>
                            </div>
                            <p
                              class="mb-0 fw-medium text-800 text-truncate fs--2"
                            >
                              Events
                            </p>
                          </a>
                        </div>
                        <div class="col-12">
                          <a
                            class="btn btn-outline-primary btn-sm mt-4"
                            href="invoice.html#!"
                            >Show more</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link pe-0 ps-2"
                id="navbarDropdownUser"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="avatar avatar-xl">
                  <img
                    class="rounded-circle"
                    src="assets/img/team/3-thumb.png"
                    alt=""
                  />
                </div>
              </a>
              <div
                class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
                aria-labelledby="navbarDropdownUser"
              >
                <div class="bg-white dark__bg-1000 rounded-2 py-2">
                  <a
                    class="dropdown-item fw-bold text-warning"
                    href="invoice.html#!"
                    ><span class="fas fa-crown me-1"></span
                    ><span>Go Pro</span></a
                  >
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="invoice.html#!">Set status</a>
                  <a class="dropdown-item" href="../../pages/user/profile.html"
                    >Profile &amp; account</a
                  >
                  <a class="dropdown-item" href="invoice.html#!">Feedback</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="../../pages/user/settings.html"
                    >Settings</a
                  >
                  <a
                    class="dropdown-item"
                    href="../../pages/authentication/card/logout.html"
                    >Logout</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <hr class="my-2 d-none d-lg-block" />
        <div
          class="collapse navbar-collapse scrollbar py-lg-2"
          id="navbarDoubleTop"
        >
          <ul
            class="navbar-nav"
            data-top-nav-dropdowns="data-top-nav-dropdowns"
          >
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="dashboards"
                >Dashboard</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="dashboards"
              >
                <div class="bg-white dark__bg-1000 rounded-3 py-2">
                  <a
                    class="dropdown-item link-600 fw-medium"
                    href="../../index.html"
                    >Default</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/analytics.html"
                    >Analytics</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/crm.html"
                    >CRM</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/e-commerce.html"
                    >E commerce</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/lms.html"
                    >LMS<span class="badge rounded-pill ms-2 badge-soft-success"
                      >New</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/project-management.html"
                    >Management</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/saas.html"
                    >SaaS</a
                  >
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="apps"
                >App</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="apps"
              >
                <div class="card navbar-card-app shadow-none dark__bg-1000">
                  <div class="card-body scrollbar max-h-dropdown">
                    <img
                      class="img-dropdown"
                      src="assets/img/icons/spot-illustrations/authentication-corner.png"
                      width="130"
                      alt=""
                    />
                    <div class="row">
                      <div class="col-6 col-md-5">
                        <div class="nav flex-column">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../calendar.html"
                            >Calendar</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../chat.html"
                            >Chat</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-list.html"
                            >Course list</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Email</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../email/inbox.html"
                            >Inbox</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../email/email-detail.html"
                            >Email detail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../email/compose.html"
                            >Compose</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Events</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../events/create-an-event.html"
                            >Create an event</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../events/event-detail.html"
                            >Event detail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../events/event-list.html"
                            >Event list</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">
                            E-Learning
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-list.html"
                            >Course list</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-grid.html"
                            >Course grid</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-details.html"
                            >Course details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/create-a-course.html"
                            >Create a course</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/student-overview.html"
                            >Student overview</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/trainer-profile.html"
                            >Trainer profile</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            E-Commerce
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="product/product-list.html"
                            >Product list</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="product/product-grid.html"
                            >Product grid</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="product/product-details.html"
                            >Product details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="orders/order-list.html"
                            >Order list</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="orders/order-details.html"
                            >Order details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="customers.html"
                            >Customers</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="customer-details.html"
                            >Customer details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="shopping-cart.html"
                            >Shopping cart</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="checkout.html"
                            >Checkout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="billing.html"
                            >Billing</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="invoice.html"
                            >Invoice</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Social</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/feed.html"
                            >Feed</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/activity-log.html"
                            >Activity log</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/notifications.html"
                            >Notifications</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/followers.html"
                            >Followers</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="pagess"
                >Pages</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="pagess"
              >
                <div class="card navbar-card-pages shadow-none dark__bg-1000">
                  <div class="card-body scrollbar max-h-dropdown">
                    <img
                      class="img-dropdown"
                      src="assets/img/icons/spot-illustrations/authentication-corner.png"
                      width="130"
                      alt=""
                    />
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Simple Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/login.html"
                            >Login</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/logout.html"
                            >Logout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/register.html"
                            >Register</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/forgot-password.html"
                            >Forgot password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/confirm-mail.html"
                            >Confirm mail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/reset-password.html"
                            >Reset password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/lock-screen.html"
                            >Lock screen</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Card Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/login.html"
                            >Login</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/logout.html"
                            >Logout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/register.html"
                            >Register</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/forgot-password.html"
                            >Forgot password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/confirm-mail.html"
                            >Confirm mail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/reset-password.html"
                            >Reset password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/lock-screen.html"
                            >Lock screen</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Split Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/login.html"
                            >Login</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/logout.html"
                            >Logout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/register.html"
                            >Register</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/forgot-password.html"
                            >Forgot password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/confirm-mail.html"
                            >Confirm mail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/reset-password.html"
                            >Reset password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/lock-screen.html"
                            >Lock screen</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Layouts</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/navbar-vertical.html"
                            >Navbar vertical</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/navbar-top.html"
                            >Top nav</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/navbar-double-top.html"
                            >Double top<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/combo-nav.html"
                            >Combo nav</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Others</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/starter.html"
                            >Starter</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/landing.html"
                            >Landing</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">User</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/user/profile.html"
                            >Profile</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/user/settings.html"
                            >Settings</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Pricing</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/pricing/pricing-default.html"
                            >Pricing default</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/pricing/pricing-alt.html"
                            >Pricing alt</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Errors</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/errors/404.html"
                            >404</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/errors/500.html"
                            >500</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Miscellaneous
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/miscellaneous/associations.html"
                            >Associations</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/miscellaneous/invite-people.html"
                            >Invite people</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/miscellaneous/privacy-policy.html"
                            >Privacy policy</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">FAQ</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/faq/faq-basic.html"
                            >Faq basic</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/faq/faq-alt.html"
                            >Faq alt</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/faq/faq-accordion.html"
                            >Faq accordion</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Other Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/wizard.html"
                            >Wizard</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../index.html#authentication-modal"
                            data-bs-toggle="modal"
                            >Modal</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="moduless"
                >Modules</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="moduless"
              >
                <div
                  class="card navbar-card-components shadow-none dark__bg-1000"
                >
                  <div class="card-body scrollbar max-h-dropdown">
                    <img
                      class="img-dropdown"
                      src="assets/img/icons/spot-illustrations/authentication-corner.png"
                      width="130"
                      alt=""
                    />
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Components
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/maps/leaflet-map.html"
                            >Leaflet map</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/accordion.html"
                            >Accordion</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/alerts.html"
                            >Alerts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/anchor.html"
                            >Anchor</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/animated-icons.html"
                            >Animated icons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/background.html"
                            >Background</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/badges.html"
                            >Badges</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/bottom-bar.html"
                            >Bottom bar<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/breadcrumbs.html"
                            >Breadcrumbs</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/buttons.html"
                            >Buttons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/calendar.html"
                            >Calendar</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/cards.html"
                            >Cards</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column mt-md-4 pt-md-1">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/carousel/bootstrap.html"
                            >Bootstrap carousel</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/carousel/swiper.html"
                            >Swiper</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/collapse.html"
                            >Collapse</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/cookie-notice.html"
                            >Cookie notice</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/countup.html"
                            >Countup</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/draggable.html"
                            >Draggable</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/dropdowns.html"
                            >Dropdowns</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/jquery-components.html"
                            >Jquery<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/list-group.html"
                            >List group</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/modals.html"
                            >Modals</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/navs.html"
                            >Navs</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/navbar.html"
                            >Navbar</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column mt-xxl-4 pt-xxl-1">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/vertical-navbar.html"
                            >Navbar vertical</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/top-navbar.html"
                            >Top nav</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/double-top-navbar.html"
                            >Double top<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/combo-navbar.html"
                            >Combo nav</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/tabs.html"
                            >Tabs</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/offcanvas.html"
                            >Offcanvas</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/avatar.html"
                            >Avatar</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/images.html"
                            >Images</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/figures.html"
                            >Figures</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/hoverbox.html"
                            >Hoverbox</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/lightbox.html"
                            >Lightbox</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/progress-bar.html"
                            >Progress bar</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column mt-xxl-4 pt-xxl-1">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/placeholder.html"
                            >Placeholder</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pagination.html"
                            >Pagination</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/popovers.html"
                            >Popovers</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/scrollspy.html"
                            >Scrollspy</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/search.html"
                            >Search</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/spinners.html"
                            >Spinners</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/timeline.html"
                            >Timeline</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/toasts.html"
                            >Toasts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/tooltips.html"
                            >Tooltips</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Forms</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/form-control.html"
                            >Form control</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/input-group.html"
                            >Input group</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/select.html"
                            >Select</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/checks.html"
                            >Checks</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/range.html"
                            >Range</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/layout.html"
                            >Layout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/advance-select.html"
                            >Advance select</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/date-picker.html"
                            >Date picker</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/editor.html"
                            >Editor</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/emoji-button.html"
                            >Emoji button</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/file-uploader.html"
                            >File uploader</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/rating.html"
                            >Rating</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/floating-labels.html"
                            >Floating labels</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/wizard.html"
                            >Wizard</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/validation.html"
                            >Validation</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Tables</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/tables/basic-tables.html"
                            >Basic tables</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/tables/advance-tables.html"
                            >Advance tables</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/tables/bulk-select.html"
                            >Bulk select</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Charts</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/chartjs.html"
                            >Chartjs</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">ECharts</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/d3js.html"
                            >D3js<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/line-charts.html"
                            >Line charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/bar-charts.html"
                            >Bar charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/candlestick-charts.html"
                            >Candlestick charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/geo-map.html"
                            >Geo map</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/scatter-charts.html"
                            >Scatter charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/pie-charts.html"
                            >Pie charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/radar-charts.html"
                            >Radar charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/heatmap-charts.html"
                            >Heatmap charts</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Utilities
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/treeview.html"
                            >Treeview</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/typed-text.html"
                            >Typed text</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/videos/embed.html"
                            >Embed</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/videos/plyr.html"
                            >Plyr</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/borders.html"
                            >Borders</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/clearfix.html"
                            >Clearfix</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/colors.html"
                            >Colors</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/colored-links.html"
                            >Colored links</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/display.html"
                            >Display</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/flex.html"
                            >Flex</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/float.html"
                            >Float</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/grid.html"
                            >Grid</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/overlayscrollbar.html"
                            >Overlay scrollbar<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/position.html"
                            >Position</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/spacing.html"
                            >Spacing</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column pt-xxl-1">
                          <p class="nav-link text-700 mb-0 fw-bold">Icons</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/how-to-use.html"
                            >How to use</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/font-awesome.html"
                            >Font awesome</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/bootstrap-icons.html"
                            >Bootstrap icons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/feather.html"
                            >Feather</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Maps</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/material-icons.html"
                            >Material icons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/maps/google-map.html"
                            >Google map</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="documentations"
                >Documentation</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="documentations"
              >
                <div class="bg-white dark__bg-1000 rounded-3 py-2">
                  <a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/getting-started.html"
                    >Getting started</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/configuration.html"
                    >Configuration</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/styling.html"
                    >Styling<span
                      class="badge rounded-pill ms-2 badge-soft-success"
                      >Updated</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/dark-mode.html"
                    >Dark mode</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/plugin.html"
                    >Plugin</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/faq.html"
                    >Faq</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/gulp.html"
                    >Gulp</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/design-file.html"
                    >Design file</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../changelog.html"
                    >Changelog</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <nav
      class="navbar navbar-light navbar-vertical navbar-expand-xl"
      style="display: none"
    >
      <div class="d-flex align-items-center">
        <div class="toggle-icon-wrapper">
          <button
            class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Toggle Navigation"
          >
            <span class="navbar-toggle-icon"
              ><span class="toggle-line"></span
            ></span>
          </button>
        </div>
        <a class="navbar-brand" href="../../index.html">
          <div class="d-flex align-items-center py-3">
            <img
              class="me-2"
              src="assets/img/icons/spot-illustrations/falcon.png"
              alt=""
              width="40"
            /><span class="font-sans-serif">falcon</span>
          </div>
        </a>
      </div>
      <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div class="navbar-vertical-content scrollbar">
          <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            <li class="nav-item">
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#dashboard"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="dashboard"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-chart-pie"></span></span
                  ><span class="nav-link-text ps-1">Dashboard</span>
                </div>
              </a>
              <ul class="nav collapse" id="dashboard">
                <li class="nav-item">
                  <a class="nav-link" href="../../index.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Default</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../dashboard/analytics.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Analytics</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../dashboard/crm.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">CRM</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../dashboard/e-commerce.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">E commerce</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../dashboard/lms.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">LMS</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >New</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../dashboard/project-management.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Management</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../dashboard/saas.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">SaaS</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <!-- label-->
              <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div class="col-auto navbar-vertical-label">App</div>
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <!-- parent pages--><a
                class="nav-link"
                href="../calendar.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-calendar-alt"></span></span
                  ><span class="nav-link-text ps-1">Calendar</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link"
                href="../chat.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-comments"></span></span
                  ><span class="nav-link-text ps-1">Chat</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#email"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="email"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-envelope-open"></span></span
                  ><span class="nav-link-text ps-1">Email</span>
                </div>
              </a>
              <ul class="nav collapse" id="email">
                <li class="nav-item">
                  <a class="nav-link" href="../email/inbox.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Inbox</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../email/email-detail.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Email detail</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../email/compose.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Compose</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#events"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="events"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-calendar-day"></span></span
                  ><span class="nav-link-text ps-1">Events</span>
                </div>
              </a>
              <ul class="nav collapse" id="events">
                <li class="nav-item">
                  <a class="nav-link" href="../events/create-an-event.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Create an event</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../events/event-detail.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Event detail</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../events/event-list.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Event list</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#e-commerce"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="true"
                aria-controls="e-commerce"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-shopping-cart"></span></span
                  ><span class="nav-link-text ps-1">E commerce</span>
                </div>
              </a>
              <ul class="nav collapse show" id="e-commerce">
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#product"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="e-commerce"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Product</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="product">
                    <li class="nav-item">
                      <a class="nav-link" href="product/product-list.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Product list</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="product/product-grid.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Product grid</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="product/product-details.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Product details</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#orders"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="e-commerce"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Orders</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="orders">
                    <li class="nav-item">
                      <a class="nav-link" href="orders/order-list.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Order list</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="orders/order-details.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Order details</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="customers.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Customers</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="customer-details.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Customer details</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="shopping-cart.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Shopping cart</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="checkout.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Checkout</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="billing.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Billing</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link active" href="invoice.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Invoice</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#e-learning"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="e-learning"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-graduation-cap"></span></span
                  ><span class="nav-link-text ps-1">E learning</span
                  ><span class="badge rounded-pill ms-2 badge-soft-success"
                    >New</span
                  >
                </div>
              </a>
              <ul class="nav collapse" id="e-learning">
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#course"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="e-learning"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Course</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="course">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../e-learning/course/course-list.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Course list</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../e-learning/course/course-grid.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Course grid</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../e-learning/course/course-details.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Course details</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../e-learning/course/create-a-course.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Create a course</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../e-learning/student-overview.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Student overview</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../e-learning/trainer-profile.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Trainer profile</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link"
                href="../kanban.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fab fa-trello"></span></span
                  ><span class="nav-link-text ps-1">Kanban</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#social"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="social"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-share-alt"></span></span
                  ><span class="nav-link-text ps-1">Social</span>
                </div>
              </a>
              <ul class="nav collapse" id="social">
                <li class="nav-item">
                  <a class="nav-link" href="../social/feed.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Feed</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../social/activity-log.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Activity log</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../social/notifications.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Notifications</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../social/followers.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Followers</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <!-- label-->
              <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div class="col-auto navbar-vertical-label">Pages</div>
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <!-- parent pages--><a
                class="nav-link"
                href="../../pages/starter.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-flag"></span></span
                  ><span class="nav-link-text ps-1">Starter</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link"
                href="../../pages/landing.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-globe"></span></span
                  ><span class="nav-link-text ps-1">Landing</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#authentication"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="authentication"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-lock"></span></span
                  ><span class="nav-link-text ps-1">Authentication</span>
                </div>
              </a>
              <ul class="nav collapse" id="authentication">
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#simple"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="authentication"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Simple</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="simple">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/login.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Login</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/logout.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Logout</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/register.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Register</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/forgot-password.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Forgot password</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/confirm-mail.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Confirm mail</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/reset-password.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Reset password</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/simple/lock-screen.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Lock screen</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#card"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="authentication"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Card</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="card">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/login.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Login</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/logout.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Logout</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/register.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Register</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/forgot-password.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Forgot password</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/confirm-mail.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Confirm mail</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/reset-password.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Reset password</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/card/lock-screen.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Lock screen</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#split"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="authentication"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Split</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="split">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/login.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Login</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/logout.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Logout</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/register.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Register</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/forgot-password.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Forgot password</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/confirm-mail.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Confirm mail</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/reset-password.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Reset password</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../pages/authentication/split/lock-screen.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Lock screen</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../pages/authentication/wizard.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Wizard</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../index.html#authentication-modal"
                    data-bs-toggle="modal"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Modal</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#user"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="user"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-user"></span></span
                  ><span class="nav-link-text ps-1">User</span>
                </div>
              </a>
              <ul class="nav collapse" id="user">
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/user/profile.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Profile</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/user/settings.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Settings</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#pricing"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="pricing"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-tags"></span></span
                  ><span class="nav-link-text ps-1">Pricing</span>
                </div>
              </a>
              <ul class="nav collapse" id="pricing">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../pages/pricing/pricing-default.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Pricing default</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../pages/pricing/pricing-alt.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Pricing alt</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#faq"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="faq"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-question-circle"></span></span
                  ><span class="nav-link-text ps-1">Faq</span>
                </div>
              </a>
              <ul class="nav collapse" id="faq">
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/faq/faq-basic.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Faq basic</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/faq/faq-alt.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Faq alt</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/faq/faq-accordion.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Faq accordion</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#errors"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="errors"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-exclamation-triangle"></span></span
                  ><span class="nav-link-text ps-1">Errors</span>
                </div>
              </a>
              <ul class="nav collapse" id="errors">
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/errors/404.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">404</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../pages/errors/500.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">500</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#miscellaneous"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="miscellaneous"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-thumbtack"></span></span
                  ><span class="nav-link-text ps-1">Miscellaneous</span>
                </div>
              </a>
              <ul class="nav collapse" id="miscellaneous">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../pages/miscellaneous/associations.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Associations</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../pages/miscellaneous/invite-people.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Invite people</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../pages/miscellaneous/privacy-policy.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Privacy policy</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#Layouts"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="Layouts"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="far fa-window-restore"></span></span
                  ><span class="nav-link-text ps-1">Layouts</span>
                </div>
              </a>
              <ul class="nav collapse" id="Layouts">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../demo/navbar-vertical.html"
                    target="_blank"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Navbar vertical</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../demo/navbar-top.html"
                    target="_blank"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Top nav</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../demo/navbar-double-top.html"
                    target="_blank"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Double top</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >New</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../demo/combo-nav.html"
                    target="_blank"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Combo nav</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <!-- label-->
              <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div class="col-auto navbar-vertical-label">Modules</div>
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#forms"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="forms"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-file-alt"></span></span
                  ><span class="nav-link-text ps-1">Forms</span>
                </div>
              </a>
              <ul class="nav collapse" id="forms">
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#basic"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="forms"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Basic</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="basic">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/basic/form-control.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Form control</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/basic/input-group.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Input group</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/basic/select.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Select</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/basic/checks.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Checks</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/basic/range.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Range</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/basic/layout.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Layout</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#advance"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="forms"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Advance</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="advance">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/advance/advance-select.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Advance select</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/advance/date-picker.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Date picker</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/advance/editor.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Editor</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/advance/emoji-button.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Emoji button</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/advance/file-uploader.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">File uploader</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/forms/advance/rating.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Rating</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/forms/floating-labels.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Floating labels</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/forms/wizard.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Wizard</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/forms/validation.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Validation</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#tables"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="tables"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-table"></span></span
                  ><span class="nav-link-text ps-1">Tables</span>
                </div>
              </a>
              <ul class="nav collapse" id="tables">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/tables/basic-tables.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Basic tables</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/tables/advance-tables.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Advance tables</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/tables/bulk-select.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Bulk select</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#charts"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="charts"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-chart-line"></span></span
                  ><span class="nav-link-text ps-1">Charts</span>
                </div>
              </a>
              <ul class="nav collapse" id="charts">
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/charts/chartjs.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Chartjs</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/charts/d3js.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">D3js</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >New</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#eCharts"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="charts"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">ECharts</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="eCharts">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/line-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Line charts</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/bar-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Bar charts</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/candlestick-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Candlestick charts</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/geo-map.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Geo map</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/scatter-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Scatter charts</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/pie-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Pie charts</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/radar-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Radar charts</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/heatmap-charts.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Heatmap charts</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/charts/echarts/how-to-use.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">How to use</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#icons"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="icons"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-shapes"></span></span
                  ><span class="nav-link-text ps-1">Icons</span>
                </div>
              </a>
              <ul class="nav collapse" id="icons">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/icons/font-awesome.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Font awesome</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/icons/bootstrap-icons.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Bootstrap icons</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/icons/feather.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Feather</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/icons/material-icons.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Material icons</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#maps"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="maps"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-map"></span></span
                  ><span class="nav-link-text ps-1">Maps</span>
                </div>
              </a>
              <ul class="nav collapse" id="maps">
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/maps/google-map.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Google map</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/maps/leaflet-map.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Leaflet map</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#components"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="components"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-puzzle-piece"></span></span
                  ><span class="nav-link-text ps-1">Components</span>
                </div>
              </a>
              <ul class="nav collapse" id="components">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/accordion.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Accordion</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/alerts.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Alerts</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/anchor.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Anchor</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/animated-icons.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Animated icons</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/background.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Background</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/badges.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Badges</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/bottom-bar.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Bottom bar</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >New</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/breadcrumbs.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Breadcrumbs</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/buttons.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Buttons</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/calendar.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Calendar</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/cards.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Cards</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#carousel"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="components"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Carousel</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="carousel">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/carousel/bootstrap.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Bootstrap</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/carousel/swiper.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Swiper</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/collapse.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Collapse</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/cookie-notice.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Cookie notice</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/countup.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Countup</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/draggable.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Draggable</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/dropdowns.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Dropdowns</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/jquery-components.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Jquery</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >New</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/list-group.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">List group</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/modals.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Modals</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#navs-_and_-Tabs"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="components"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Navs &amp; Tabs</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="navs-_and_-Tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/navs.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Navs</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/navbar.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Navbar</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/vertical-navbar.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1"
                            >Navbar vertical</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/top-navbar.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Top nav</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/double-top-navbar.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Double top</span
                          ><span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          >
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/combo-navbar.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Combo nav</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/navs-and-tabs/tabs.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Tabs</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/offcanvas.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Offcanvas</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#pictures"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="components"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Pictures</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="pictures">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/pictures/avatar.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Avatar</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/pictures/images.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Images</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/pictures/figures.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Figures</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/pictures/hoverbox.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Hoverbox</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/pictures/lightbox.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Lightbox</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/progress-bar.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Progress bar</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/placeholder.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Placeholder</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/pagination.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Pagination</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/popovers.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Popovers</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/scrollspy.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Scrollspy</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/search.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Search</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/spinners.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Spinners</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/timeline.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Timeline</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/toasts.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Toasts</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/tooltips.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Tooltips</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/treeview.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Treeview</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/components/typed-text.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Typed text</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#videos"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="components"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Videos</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="videos">
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/videos/embed.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Embed</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="../../modules/components/videos/plyr.html"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Plyr</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#utilities"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="utilities"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-fire"></span></span
                  ><span class="nav-link-text ps-1">Utilities</span>
                </div>
              </a>
              <ul class="nav collapse" id="utilities">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/borders.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Borders</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/clearfix.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Clearfix</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/colors.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Colors</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/colored-links.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Colored links</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/display.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Display</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/utilities/flex.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Flex</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/utilities/float.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Float</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="../../modules/utilities/grid.html">
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Grid</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/overlayscrollbar.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Overlay scrollbar</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >New</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/position.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Position</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/spacing.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Spacing</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/sizing.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Sizing</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/stretched-link.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Stretched link</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/text-truncation.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Text truncation</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/typography.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Typography</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/vertical-align.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Vertical align</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../modules/utilities/visibility.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Visibility</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link"
                href="../../widgets.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-poll"></span></span
                  ><span class="nav-link-text ps-1">Widgets</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#multi-level"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="multi-level"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-layer-group"></span></span
                  ><span class="nav-link-text ps-1">Multi level</span>
                </div>
              </a>
              <ul class="nav collapse" id="multi-level">
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#level-two"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="multi-level"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Level two</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="level-two">
                    <li class="nav-item">
                      <a class="nav-link" href="../../index.html#!.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Item 1</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="../../index.html#!.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Item 2</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#level-three"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="multi-level"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Level three</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="level-three">
                    <li class="nav-item">
                      <a class="nav-link" href="../../index.html#!.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Item 3</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link dropdown-indicator"
                        href="invoice.html#item-4"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="level-three"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Item 4</span>
                        </div> </a
                      ><!-- more inner pages-->
                      <ul class="nav collapse" id="item-4">
                        <li class="nav-item">
                          <a class="nav-link" href="../../index.html#!.html">
                            <div class="d-flex align-items-center">
                              <span class="nav-link-text ps-1">Item 5</span>
                            </div> </a
                          ><!-- more inner pages-->
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="../../index.html#!.html">
                            <div class="d-flex align-items-center">
                              <span class="nav-link-text ps-1">Item 6</span>
                            </div> </a
                          ><!-- more inner pages-->
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link dropdown-indicator"
                    href="invoice.html#level-four"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="multi-level"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Level four</span>
                    </div> </a
                  ><!-- more inner pages-->
                  <ul class="nav collapse" id="level-four">
                    <li class="nav-item">
                      <a class="nav-link" href="../../index.html#!.html">
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Item 6</span>
                        </div> </a
                      ><!-- more inner pages-->
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link dropdown-indicator"
                        href="invoice.html#item-7"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls="level-four"
                      >
                        <div class="d-flex align-items-center">
                          <span class="nav-link-text ps-1">Item 7</span>
                        </div> </a
                      ><!-- more inner pages-->
                      <ul class="nav collapse" id="item-7">
                        <li class="nav-item">
                          <a class="nav-link" href="../../index.html#!.html">
                            <div class="d-flex align-items-center">
                              <span class="nav-link-text ps-1">Item 8</span>
                            </div> </a
                          ><!-- more inner pages-->
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link dropdown-indicator"
                            href="invoice.html#item-9"
                            data-bs-toggle="collapse"
                            aria-expanded="false"
                            aria-controls="item-7"
                          >
                            <div class="d-flex align-items-center">
                              <span class="nav-link-text ps-1">Item 9</span>
                            </div> </a
                          ><!-- more inner pages-->
                          <ul class="nav collapse" id="item-9">
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                href="../../index.html#!.html"
                              >
                                <div class="d-flex align-items-center">
                                  <span class="nav-link-text ps-1"
                                    >Item 10</span
                                  >
                                </div> </a
                              ><!-- more inner pages-->
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                href="../../index.html#!.html"
                              >
                                <div class="d-flex align-items-center">
                                  <span class="nav-link-text ps-1"
                                    >Item 11</span
                                  >
                                </div> </a
                              ><!-- more inner pages-->
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <!-- label-->
              <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div class="col-auto navbar-vertical-label">Documentation</div>
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <!-- parent pages--><a
                class="nav-link"
                href="../../documentation/getting-started.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-rocket"></span></span
                  ><span class="nav-link-text ps-1">Getting started</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link dropdown-indicator"
                href="invoice.html#customization"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="false"
                aria-controls="customization"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-wrench"></span></span
                  ><span class="nav-link-text ps-1">Customization</span>
                </div>
              </a>
              <ul class="nav collapse" id="customization">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../documentation/customization/configuration.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Configuration</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../documentation/customization/styling.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Styling</span
                      ><span class="badge rounded-pill ms-2 badge-soft-success"
                        >Updated</span
                      >
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../documentation/customization/dark-mode.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Dark mode</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    href="../../documentation/customization/plugin.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="nav-link-text ps-1">Plugin</span>
                    </div> </a
                  ><!-- more inner pages-->
                </li>
              </ul>
              <!-- parent pages--><a
                class="nav-link"
                href="../../documentation/faq.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-question-circle"></span></span
                  ><span class="nav-link-text ps-1">Faq</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link"
                href="../../documentation/gulp.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fab fa-gulp"></span></span
                  ><span class="nav-link-text ps-1">Gulp</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link"
                href="../../documentation/design-file.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-palette"></span></span
                  ><span class="nav-link-text ps-1">Design file</span>
                </div> </a
              ><!-- parent pages--><a
                class="nav-link"
                href="../../changelog.html"
                role="button"
              >
                <div class="d-flex align-items-center">
                  <span class="nav-link-icon"
                    ><span class="fas fa-code-branch"></span></span
                  ><span class="nav-link-text ps-1">Changelog</span>
                </div>
              </a>
            </li>
          </ul>
          <div class="settings mb-3">
            <div class="card shadow-none">
              <div class="card-body alert mb-0" role="alert">
                <div class="btn-close-falcon-container">
                  <button
                    class="btn btn-link btn-close-falcon p-0"
                    aria-label="Close"
                    data-bs-dismiss="alert"
                  ></button>
                </div>
                <div class="text-center">
                  <img
                    src="assets/img/icons/spot-illustrations/navbar-vertical.png"
                    alt=""
                    width="80"
                  />
                  <p class="fs--2 mt-2">
                    Loving what you see? <br />Get your copy of
                    <a href="invoice.html#!">Falcon</a>
                  </p>
                  <div class="d-grid">
                    <a
                      class="btn btn-sm btn-purchase"
                      href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template/"
                      target="_blank"
                      >Purchase</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <nav
      class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg"
      style="display: none"
    >
      <button
        class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarStandard"
        aria-controls="navbarStandard"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span class="navbar-toggle-icon"
          ><span class="toggle-line"></span
        ></span>
      </button>
      <a class="navbar-brand me-1 me-sm-3" href="../../index.html">
        <div class="d-flex align-items-center">
          <img
            class="me-2"
            src="assets/img/icons/spot-illustrations/falcon.png"
            alt=""
            width="40"
          /><span class="font-sans-serif">falcon</span>
        </div>
      </a>
      <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
        <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="invoice.html#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="dashboards"
              >Dashboard</a
            >
            <div
              class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
              aria-labelledby="dashboards"
            >
              <div class="bg-white dark__bg-1000 rounded-3 py-2">
                <a
                  class="dropdown-item link-600 fw-medium"
                  href="../../index.html"
                  >Default</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../dashboard/analytics.html"
                  >Analytics</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../dashboard/crm.html"
                  >CRM</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../dashboard/e-commerce.html"
                  >E commerce</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../dashboard/lms.html"
                  >LMS<span class="badge rounded-pill ms-2 badge-soft-success"
                    >New</span
                  ></a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../dashboard/project-management.html"
                  >Management</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../dashboard/saas.html"
                  >SaaS</a
                >
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="invoice.html#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="apps"
              >App</a
            >
            <div
              class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
              aria-labelledby="apps"
            >
              <div class="card navbar-card-app shadow-none dark__bg-1000">
                <div class="card-body scrollbar max-h-dropdown">
                  <img
                    class="img-dropdown"
                    src="assets/img/icons/spot-illustrations/authentication-corner.png"
                    width="130"
                    alt=""
                  />
                  <div class="row">
                    <div class="col-6 col-md-5">
                      <div class="nav flex-column">
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../calendar.html"
                          >Calendar</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../chat.html"
                          >Chat</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/course/course-list.html"
                          >Course list</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">Email</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../email/inbox.html"
                          >Inbox</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../email/email-detail.html"
                          >Email detail</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../email/compose.html"
                          >Compose</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">Events</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../events/create-an-event.html"
                          >Create an event</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../events/event-detail.html"
                          >Event detail</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../events/event-list.html"
                          >Event list</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">E-Learning</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/course/course-list.html"
                          >Course list</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/course/course-grid.html"
                          >Course grid</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/course/course-details.html"
                          >Course details</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/course/create-a-course.html"
                          >Create a course</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/student-overview.html"
                          >Student overview</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../e-learning/trainer-profile.html"
                          >Trainer profile</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-md-4">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">E-Commerce</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="product/product-list.html"
                          >Product list</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="product/product-grid.html"
                          >Product grid</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="product/product-details.html"
                          >Product details</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="orders/order-list.html"
                          >Order list</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="orders/order-details.html"
                          >Order details</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="customers.html"
                          >Customers</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="customer-details.html"
                          >Customer details</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="shopping-cart.html"
                          >Shopping cart</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="checkout.html"
                          >Checkout</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="billing.html"
                          >Billing</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="invoice.html"
                          >Invoice</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">Social</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../social/feed.html"
                          >Feed</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../social/activity-log.html"
                          >Activity log</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../social/notifications.html"
                          >Notifications</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../social/followers.html"
                          >Followers</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="invoice.html#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="pagess"
              >Pages</a
            >
            <div
              class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
              aria-labelledby="pagess"
            >
              <div class="card navbar-card-pages shadow-none dark__bg-1000">
                <div class="card-body scrollbar max-h-dropdown">
                  <img
                    class="img-dropdown"
                    src="assets/img/icons/spot-illustrations/authentication-corner.png"
                    width="130"
                    alt=""
                  />
                  <div class="row">
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">
                          Simple Auth
                        </p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/login.html"
                          >Login</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/logout.html"
                          >Logout</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/register.html"
                          >Register</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/forgot-password.html"
                          >Forgot password</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/confirm-mail.html"
                          >Confirm mail</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/reset-password.html"
                          >Reset password</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/simple/lock-screen.html"
                          >Lock screen</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Card Auth</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/login.html"
                          >Login</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/logout.html"
                          >Logout</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/register.html"
                          >Register</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/forgot-password.html"
                          >Forgot password</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/confirm-mail.html"
                          >Confirm mail</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/reset-password.html"
                          >Reset password</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/card/lock-screen.html"
                          >Lock screen</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Split Auth</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/login.html"
                          >Login</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/logout.html"
                          >Logout</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/register.html"
                          >Register</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/forgot-password.html"
                          >Forgot password</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/confirm-mail.html"
                          >Confirm mail</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/reset-password.html"
                          >Reset password</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/split/lock-screen.html"
                          >Lock screen</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Layouts</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../demo/navbar-vertical.html"
                          >Navbar vertical</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../demo/navbar-top.html"
                          >Top nav</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../demo/navbar-double-top.html"
                          >Double top<span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          ></a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../demo/combo-nav.html"
                          >Combo nav</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">Others</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/starter.html"
                          >Starter</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/landing.html"
                          >Landing</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">User</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/user/profile.html"
                          >Profile</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/user/settings.html"
                          >Settings</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Pricing</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/pricing/pricing-default.html"
                          >Pricing default</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/pricing/pricing-alt.html"
                          >Pricing alt</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Errors</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/errors/404.html"
                          >404</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/errors/500.html"
                          >500</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">
                          Miscellaneous
                        </p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/miscellaneous/associations.html"
                          >Associations</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/miscellaneous/invite-people.html"
                          >Invite people</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/miscellaneous/privacy-policy.html"
                          >Privacy policy</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">FAQ</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/faq/faq-basic.html"
                          >Faq basic</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/faq/faq-alt.html"
                          >Faq alt</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/faq/faq-accordion.html"
                          >Faq accordion</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Other Auth</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../pages/authentication/wizard.html"
                          >Wizard</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../index.html#authentication-modal"
                          data-bs-toggle="modal"
                          >Modal</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="invoice.html#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="moduless"
              >Modules</a
            >
            <div
              class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
              aria-labelledby="moduless"
            >
              <div
                class="card navbar-card-components shadow-none dark__bg-1000"
              >
                <div class="card-body scrollbar max-h-dropdown">
                  <img
                    class="img-dropdown"
                    src="assets/img/icons/spot-illustrations/authentication-corner.png"
                    width="130"
                    alt=""
                  />
                  <div class="row">
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Components</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/maps/leaflet-map.html"
                          >Leaflet map</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/accordion.html"
                          >Accordion</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/alerts.html"
                          >Alerts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/anchor.html"
                          >Anchor</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/animated-icons.html"
                          >Animated icons</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/background.html"
                          >Background</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/badges.html"
                          >Badges</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/bottom-bar.html"
                          >Bottom bar<span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          ></a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/breadcrumbs.html"
                          >Breadcrumbs</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/buttons.html"
                          >Buttons</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/calendar.html"
                          >Calendar</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/cards.html"
                          >Cards</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column mt-md-4 pt-md-1">
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/carousel/bootstrap.html"
                          >Bootstrap carousel</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/carousel/swiper.html"
                          >Swiper</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/collapse.html"
                          >Collapse</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/cookie-notice.html"
                          >Cookie notice</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/countup.html"
                          >Countup</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/draggable.html"
                          >Draggable</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/dropdowns.html"
                          >Dropdowns</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/jquery-components.html"
                          >Jquery<span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          ></a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/list-group.html"
                          >List group</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/modals.html"
                          >Modals</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/navs.html"
                          >Navs</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/navbar.html"
                          >Navbar</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column mt-xxl-4 pt-xxl-1">
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/vertical-navbar.html"
                          >Navbar vertical</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/top-navbar.html"
                          >Top nav</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/double-top-navbar.html"
                          >Double top<span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          ></a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/combo-navbar.html"
                          >Combo nav</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/navs-and-tabs/tabs.html"
                          >Tabs</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/offcanvas.html"
                          >Offcanvas</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/pictures/avatar.html"
                          >Avatar</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/pictures/images.html"
                          >Images</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/pictures/figures.html"
                          >Figures</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/pictures/hoverbox.html"
                          >Hoverbox</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/pictures/lightbox.html"
                          >Lightbox</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/progress-bar.html"
                          >Progress bar</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column mt-xxl-4 pt-xxl-1">
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/placeholder.html"
                          >Placeholder</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/pagination.html"
                          >Pagination</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/popovers.html"
                          >Popovers</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/scrollspy.html"
                          >Scrollspy</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/search.html"
                          >Search</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/spinners.html"
                          >Spinners</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/timeline.html"
                          >Timeline</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/toasts.html"
                          >Toasts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/tooltips.html"
                          >Tooltips</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Forms</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/basic/form-control.html"
                          >Form control</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/basic/input-group.html"
                          >Input group</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/basic/select.html"
                          >Select</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/basic/checks.html"
                          >Checks</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/basic/range.html"
                          >Range</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/basic/layout.html"
                          >Layout</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/advance/advance-select.html"
                          >Advance select</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/advance/date-picker.html"
                          >Date picker</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/advance/editor.html"
                          >Editor</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/advance/emoji-button.html"
                          >Emoji button</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/advance/file-uploader.html"
                          >File uploader</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/advance/rating.html"
                          >Rating</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/floating-labels.html"
                          >Floating labels</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/wizard.html"
                          >Wizard</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/forms/validation.html"
                          >Validation</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Tables</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/tables/basic-tables.html"
                          >Basic tables</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/tables/advance-tables.html"
                          >Advance tables</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/tables/bulk-select.html"
                          >Bulk select</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">Charts</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/chartjs.html"
                          >Chartjs</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">ECharts</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/d3js.html"
                          >D3js<span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          ></a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/line-charts.html"
                          >Line charts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/bar-charts.html"
                          >Bar charts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/candlestick-charts.html"
                          >Candlestick charts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/geo-map.html"
                          >Geo map</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/scatter-charts.html"
                          >Scatter charts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/pie-charts.html"
                          >Pie charts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/radar-charts.html"
                          >Radar charts</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/heatmap-charts.html"
                          >Heatmap charts</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column">
                        <p class="nav-link text-700 mb-0 fw-bold">Utilities</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/treeview.html"
                          >Treeview</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/typed-text.html"
                          >Typed text</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/videos/embed.html"
                          >Embed</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/components/videos/plyr.html"
                          >Plyr</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/borders.html"
                          >Borders</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/clearfix.html"
                          >Clearfix</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/colors.html"
                          >Colors</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/colored-links.html"
                          >Colored links</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/display.html"
                          >Display</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/flex.html"
                          >Flex</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/float.html"
                          >Float</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/grid.html"
                          >Grid</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/overlayscrollbar.html"
                          >Overlay scrollbar<span
                            class="badge rounded-pill ms-2 badge-soft-success"
                            >New</span
                          ></a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/position.html"
                          >Position</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/utilities/spacing.html"
                          >Spacing</a
                        >
                      </div>
                    </div>
                    <div class="col-6 col-xxl-3">
                      <div class="nav flex-column pt-xxl-1">
                        <p class="nav-link text-700 mb-0 fw-bold">Icons</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/charts/echarts/how-to-use.html"
                          >How to use</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/icons/font-awesome.html"
                          >Font awesome</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/icons/bootstrap-icons.html"
                          >Bootstrap icons</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/icons/feather.html"
                          >Feather</a
                        >
                        <p class="nav-link text-700 mb-0 fw-bold">Maps</p>
                        <a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/icons/material-icons.html"
                          >Material icons</a
                        ><a
                          class="nav-link py-1 link-600 fw-medium"
                          href="../../modules/maps/google-map.html"
                          >Google map</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="invoice.html#"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              id="documentations"
              >Documentation</a
            >
            <div
              class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
              aria-labelledby="documentations"
            >
              <div class="bg-white dark__bg-1000 rounded-3 py-2">
                <a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/getting-started.html"
                  >Getting started</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/customization/configuration.html"
                  >Configuration</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/customization/styling.html"
                  >Styling<span
                    class="badge rounded-pill ms-2 badge-soft-success"
                    >Updated</span
                  ></a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/customization/dark-mode.html"
                  >Dark mode</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/customization/plugin.html"
                  >Plugin</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/faq.html"
                  >Faq</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/gulp.html"
                  >Gulp</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../documentation/design-file.html"
                  >Design file</a
                ><a
                  class="dropdown-item link-600 fw-medium"
                  href="../../changelog.html"
                  >Changelog</a
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ul
        class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center"
      >
        <li class="nav-item">
          <div class="theme-control-toggle fa-icon-wait px-2">
            <input
              class="form-check-input ms-0 theme-control-toggle-input"
              id="themeControlToggle"
              type="checkbox"
              data-theme-control="theme"
              value="dark"
            /><label
              class="mb-0 theme-control-toggle-label theme-control-toggle-light"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Switch to light theme"
              ><span class="fas fa-sun fs-0"></span></label
            ><label
              class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
              for="themeControlToggle"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Switch to dark theme"
              ><span class="fas fa-moon fs-0"></span
            ></label>
          </div>
        </li>
        <li class="nav-item d-none d-sm-block">
          <a
            class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait"
            href="shopping-cart.html"
            ><span
              class="fas fa-shopping-cart"
              data-fa-transform="shrink-7"
              style="font-size: 33px"
            ></span
            ><span class="notification-indicator-number">1</span></a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
            id="navbarDropdownNotification"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-hide-on-body-scroll="data-hide-on-body-scroll"
            ><span
              class="fas fa-bell"
              data-fa-transform="shrink-6"
              style="font-size: 33px"
            ></span
          ></a>
          <div
            class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
            aria-labelledby="navbarDropdownNotification"
          >
            <div class="card card-notification shadow-none">
              <div class="card-header">
                <div class="row justify-content-between align-items-center">
                  <div class="col-auto">
                    <h6 class="card-header-title mb-0">Notifications</h6>
                  </div>
                  <div class="col-auto ps-0 ps-sm-3">
                    <a class="card-link fw-normal" href="invoice.html#"
                      >Mark all as read</a
                    >
                  </div>
                </div>
              </div>
              <div class="scrollbar-overlay" style="max-height: 19rem">
                <div class="list-group list-group-flush fw-normal fs--1">
                  <div class="list-group-title border-bottom">NEW</div>
                  <div class="list-group-item">
                    <a
                      class="notification notification-flush notification-unread"
                      href="invoice.html#!"
                    >
                      <div class="notification-avatar">
                        <div class="avatar avatar-2xl me-3">
                          <img
                            class="rounded-circle"
                            src="assets/img/team/1-thumb.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1">
                          <strong>Emma Watson</strong> replied to your comment :
                          "Hello world 😍"
                        </p>
                        <span class="notification-time"
                          ><span class="me-2" role="img" aria-label="Emoji"
                            >💬</span
                          >Just now</span
                        >
                      </div>
                    </a>
                  </div>
                  <div class="list-group-item">
                    <a
                      class="notification notification-flush notification-unread"
                      href="invoice.html#!"
                    >
                      <div class="notification-avatar">
                        <div class="avatar avatar-2xl me-3">
                          <div class="avatar-name rounded-circle">
                            <span>AB</span>
                          </div>
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1">
                          <strong>Albert Brooks</strong> reacted to
                          <strong>Mia Khalifa's</strong> status
                        </p>
                        <span class="notification-time"
                          ><span class="me-2 fab fa-gratipay text-danger"></span
                          >9hr</span
                        >
                      </div>
                    </a>
                  </div>
                  <div class="list-group-title border-bottom">EARLIER</div>
                  <div class="list-group-item">
                    <a
                      class="notification notification-flush"
                      href="invoice.html#!"
                    >
                      <div class="notification-avatar">
                        <div class="avatar avatar-2xl me-3">
                          <img
                            class="rounded-circle"
                            src="assets/img/icons/weather-sm.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1">
                          The forecast today shows a low of 20&#8451; in
                          California. See today's weather.
                        </p>
                        <span class="notification-time"
                          ><span class="me-2" role="img" aria-label="Emoji"
                            >🌤️</span
                          >1d</span
                        >
                      </div>
                    </a>
                  </div>
                  <div class="list-group-item">
                    <a
                      class="border-bottom-0 notification-unread notification notification-flush"
                      href="invoice.html#!"
                    >
                      <div class="notification-avatar">
                        <div class="avatar avatar-xl me-3">
                          <img
                            class="rounded-circle"
                            src="assets/img/logos/oxford.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1">
                          <strong>University of Oxford</strong> created an event
                          : "Causal Inference Hilary 2019"
                        </p>
                        <span class="notification-time"
                          ><span class="me-2" role="img" aria-label="Emoji"
                            >✌️</span
                          >1w</span
                        >
                      </div>
                    </a>
                  </div>
                  <div class="list-group-item">
                    <a
                      class="border-bottom-0 notification notification-flush"
                      href="invoice.html#!"
                    >
                      <div class="notification-avatar">
                        <div class="avatar avatar-xl me-3">
                          <img
                            class="rounded-circle"
                            src="assets/img/team/10.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="notification-body">
                        <p class="mb-1">
                          <strong>James Cameron</strong> invited to join the
                          group: United Nations International Children's Fund
                        </p>
                        <span class="notification-time"
                          ><span class="me-2" role="img" aria-label="Emoji"
                            >🙋‍</span
                          >2d</span
                        >
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-footer text-center border-top">
                <a class="card-link d-block" href="../social/notifications.html"
                  >View all</a
                >
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown px-1">
          <a
            class="nav-link fa-icon-wait nine-dots p-1"
            id="navbarDropdownMenu"
            role="button"
            data-hide-on-body-scroll="data-hide-on-body-scroll"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="43"
              viewBox="0 0 16 16"
              fill="none"
            >
              <circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
              <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
              <circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
              <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
              <circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
              <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
              <circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
              <circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
              <circle cx="14" cy="2" r="2" fill="#6C6E71"></circle></svg
          ></a>
          <div
            class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-caret-bg"
            aria-labelledby="navbarDropdownMenu"
          >
            <div class="card shadow-none">
              <div class="scrollbar-overlay nine-dots-dropdown">
                <div class="card-body px-3">
                  <div class="row text-center gx-0 gy-0">
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="../../pages/user/profile.html"
                        target="_blank"
                      >
                        <div class="avatar avatar-2xl">
                          <img
                            class="rounded-circle"
                            src="assets/img/team/3.jpg"
                            alt=""
                          />
                        </div>
                        <p class="mb-0 fw-medium text-800 text-truncate fs--2">
                          Account
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="https://themewagon.com/"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/themewagon.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Themewagon
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="https://mailbluster.com/"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/mailbluster.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Mailbluster
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/google.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Google
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/spotify.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Spotify
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/steam.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Steam
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/github-light.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Github
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/discord.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Discord
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/xbox.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          xbox
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/trello.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Kanban
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/hp.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Hp
                        </p>
                      </a>
                    </div>
                    <div class="col-12">
                      <hr class="my-3 mx-n3 bg-200" />
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/linkedin.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Linkedin
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/twitter.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Twitter
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/facebook.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Facebook
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/instagram.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Instagram
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/pinterest.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Pinterest
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/slack.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Slack
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="invoice.html#!"
                        target="_blank"
                        ><img
                          class="rounded"
                          src="assets/img/nav-icons/deviantart.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                        <p
                          class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                        >
                          Deviantart
                        </p>
                      </a>
                    </div>
                    <div class="col-4">
                      <a
                        class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                        href="../events/event-detail.html"
                        target="_blank"
                      >
                        <div class="avatar avatar-2xl">
                          <div
                            class="avatar-name rounded-circle bg-soft-primary text-primary"
                          >
                            <span class="fs-2">E</span>
                          </div>
                        </div>
                        <p class="mb-0 fw-medium text-800 text-truncate fs--2">
                          Events
                        </p>
                      </a>
                    </div>
                    <div class="col-12">
                      <a
                        class="btn btn-outline-primary btn-sm mt-4"
                        href="invoice.html#!"
                        >Show more</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link pe-0 ps-2"
            id="navbarDropdownUser"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-xl">
              <img
                class="rounded-circle"
                src="assets/img/team/3-thumb.png"
                alt=""
              />
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser"
          >
            <div class="bg-white dark__bg-1000 rounded-2 py-2">
              <a
                class="dropdown-item fw-bold text-warning"
                href="invoice.html#!"
                ><span class="fas fa-crown me-1"></span><span>Go Pro</span></a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="invoice.html#!">Set status</a>
              <a class="dropdown-item" href="../../pages/user/profile.html"
                >Profile &amp; account</a
              >
              <a class="dropdown-item" href="invoice.html#!">Feedback</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="../../pages/user/settings.html"
                >Settings</a
              >
              <a
                class="dropdown-item"
                href="../../pages/authentication/card/logout.html"
                >Logout</a
              >
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <div class="content">
      <nav
        class="navbar navbar-light navbar-glass navbar-top navbar-expand"
        style="display: none"
      >
        <button
          class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarVerticalCollapse"
          aria-controls="navbarVerticalCollapse"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <span class="navbar-toggle-icon"
            ><span class="toggle-line"></span
          ></span>
        </button>
        <a class="navbar-brand me-1 me-sm-3" href="../../index.html">
          <div class="d-flex align-items-center">
            <img
              class="me-2"
              src="assets/img/icons/spot-illustrations/falcon.png"
              alt=""
              width="40"
            /><span class="font-sans-serif">falcon</span>
          </div>
        </a>
        <ul class="navbar-nav align-items-center d-none d-lg-block">
          <li class="nav-item">
            <div class="search-box" data-list='{"valueNames":["title"]}'>
              <form
                class="position-relative"
                data-bs-toggle="search"
                data-bs-display="static"
              >
                <input
                  class="form-control search-input fuzzy-search"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                />
                <span class="fas fa-search search-box-icon"></span>
              </form>
              <div
                class="btn-close-falcon-container position-absolute end-0 top-50 translate-middle shadow-none"
                data-bs-dismiss="search"
              >
                <button
                  class="btn btn-link btn-close-falcon p-0"
                  aria-label="Close"
                ></button>
              </div>
              <div
                class="dropdown-menu border font-base start-0 mt-2 py-0 overflow-hidden w-100"
              >
                <div class="scrollbar list py-3" style="max-height: 24rem">
                  <h6
                    class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                  >
                    Recently Browsed
                  </h6>
                  <a
                    class="dropdown-item fs--1 px-card py-1 hover-primary"
                    href="../events/event-detail.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="fas fa-circle me-2 text-300 fs--2"></span>
                      <div class="fw-normal title">
                        Pages
                        <span
                          class="fas fa-chevron-right mx-1 text-500 fs--2"
                          data-fa-transform="shrink-2"
                        ></span>
                        Events
                      </div>
                    </div>
                  </a>
                  <a
                    class="dropdown-item fs--1 px-card py-1 hover-primary"
                    href="customers.html"
                  >
                    <div class="d-flex align-items-center">
                      <span class="fas fa-circle me-2 text-300 fs--2"></span>
                      <div class="fw-normal title">
                        E-commerce
                        <span
                          class="fas fa-chevron-right mx-1 text-500 fs--2"
                          data-fa-transform="shrink-2"
                        ></span>
                        Customers
                      </div>
                    </div>
                  </a>
                  <hr class="text-200 dark__text-900" />
                  <h6
                    class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                  >
                    Suggested Filter
                  </h6>
                  <a
                    class="dropdown-item px-card py-1 fs-0"
                    href="customers.html"
                  >
                    <div class="d-flex align-items-center">
                      <span
                        class="badge fw-medium text-decoration-none me-2 badge-soft-warning"
                        >customers:</span
                      >
                      <div class="flex-1 fs--1 title">All customers list</div>
                    </div>
                  </a>
                  <a
                    class="dropdown-item px-card py-1 fs-0"
                    href="../events/event-detail.html"
                  >
                    <div class="d-flex align-items-center">
                      <span
                        class="badge fw-medium text-decoration-none me-2 badge-soft-success"
                        >events:</span
                      >
                      <div class="flex-1 fs--1 title">
                        Latest events in current month
                      </div>
                    </div>
                  </a>
                  <a
                    class="dropdown-item px-card py-1 fs-0"
                    href="product/product-grid.html"
                  >
                    <div class="d-flex align-items-center">
                      <span
                        class="badge fw-medium text-decoration-none me-2 badge-soft-info"
                        >products:</span
                      >
                      <div class="flex-1 fs--1 title">
                        Most popular products
                      </div>
                    </div>
                  </a>
                  <hr class="text-200 dark__text-900" />
                  <h6
                    class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                  >
                    Files
                  </h6>
                  <a class="dropdown-item px-card py-2" href="invoice.html#!">
                    <div class="d-flex align-items-center">
                      <div class="file-thumbnail me-2">
                        <img
                          class="border h-100 w-100 fit-cover rounded-3"
                          src="assets/img/products/3-thumb.png"
                          alt=""
                        />
                      </div>
                      <div class="flex-1">
                        <h6 class="mb-0 title">iPhone</h6>
                        <p class="fs--2 mb-0 d-flex">
                          <span class="fw-semi-bold">Antony</span
                          ><span class="fw-medium text-600 ms-2"
                            >27 Sep at 10:30 AM</span
                          >
                        </p>
                      </div>
                    </div>
                  </a>
                  <a class="dropdown-item px-card py-2" href="invoice.html#!">
                    <div class="d-flex align-items-center">
                      <div class="file-thumbnail me-2">
                        <img
                          class="img-fluid"
                          src="assets/img/icons/zip.png"
                          alt=""
                        />
                      </div>
                      <div class="flex-1">
                        <h6 class="mb-0 title">Falcon v1.8.2</h6>
                        <p class="fs--2 mb-0 d-flex">
                          <span class="fw-semi-bold">John</span
                          ><span class="fw-medium text-600 ms-2"
                            >30 Sep at 12:30 PM</span
                          >
                        </p>
                      </div>
                    </div>
                  </a>
                  <hr class="text-200 dark__text-900" />
                  <h6
                    class="dropdown-header fw-medium text-uppercase px-card fs--2 pt-0 pb-2"
                  >
                    Members
                  </h6>
                  <a
                    class="dropdown-item px-card py-2"
                    href="../../pages/user/profile.html"
                  >
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-l status-online me-2">
                        <img
                          class="rounded-circle"
                          src="assets/img/team/1.jpg"
                          alt=""
                        />
                      </div>
                      <div class="flex-1">
                        <h6 class="mb-0 title">Anna Karinina</h6>
                        <p class="fs--2 mb-0 d-flex">Technext Limited</p>
                      </div>
                    </div>
                  </a>
                  <a
                    class="dropdown-item px-card py-2"
                    href="../../pages/user/profile.html"
                  >
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-l me-2">
                        <img
                          class="rounded-circle"
                          src="assets/img/team/2.jpg"
                          alt=""
                        />
                      </div>
                      <div class="flex-1">
                        <h6 class="mb-0 title">Antony Hopkins</h6>
                        <p class="fs--2 mb-0 d-flex">Brain Trust</p>
                      </div>
                    </div>
                  </a>
                  <a
                    class="dropdown-item px-card py-2"
                    href="../../pages/user/profile.html"
                  >
                    <div class="d-flex align-items-center">
                      <div class="avatar avatar-l me-2">
                        <img
                          class="rounded-circle"
                          src="assets/img/team/3.jpg"
                          alt=""
                        />
                      </div>
                      <div class="flex-1">
                        <h6 class="mb-0 title">Emma Watson</h6>
                        <p class="fs--2 mb-0 d-flex">Google</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="text-center mt-n3">
                  <p class="fallback fw-bold fs-1 d-none">No Result Found.</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul
          class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center"
        >
          <li class="nav-item">
            <div class="theme-control-toggle fa-icon-wait px-2">
              <input
                class="form-check-input ms-0 theme-control-toggle-input"
                id="themeControlToggle"
                type="checkbox"
                data-theme-control="theme"
                value="dark"
              /><label
                class="mb-0 theme-control-toggle-label theme-control-toggle-light"
                for="themeControlToggle"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Switch to light theme"
                ><span class="fas fa-sun fs-0"></span></label
              ><label
                class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                for="themeControlToggle"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Switch to dark theme"
                ><span class="fas fa-moon fs-0"></span
              ></label>
            </div>
          </li>
          <li class="nav-item d-none d-sm-block">
            <a
              class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait"
              href="shopping-cart.html"
              ><span
                class="fas fa-shopping-cart"
                data-fa-transform="shrink-7"
                style="font-size: 33px"
              ></span
              ><span class="notification-indicator-number">1</span></a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
              id="navbarDropdownNotification"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-hide-on-body-scroll="data-hide-on-body-scroll"
              ><span
                class="fas fa-bell"
                data-fa-transform="shrink-6"
                style="font-size: 33px"
              ></span
            ></a>
            <div
              class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
              aria-labelledby="navbarDropdownNotification"
            >
              <div class="card card-notification shadow-none">
                <div class="card-header">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto">
                      <h6 class="card-header-title mb-0">Notifications</h6>
                    </div>
                    <div class="col-auto ps-0 ps-sm-3">
                      <a class="card-link fw-normal" href="invoice.html#"
                        >Mark all as read</a
                      >
                    </div>
                  </div>
                </div>
                <div class="scrollbar-overlay" style="max-height: 19rem">
                  <div class="list-group list-group-flush fw-normal fs--1">
                    <div class="list-group-title border-bottom">NEW</div>
                    <div class="list-group-item">
                      <a
                        class="notification notification-flush notification-unread"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/team/1-thumb.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>Emma Watson</strong> replied to your comment
                            : "Hello world 😍"
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >💬</span
                            >Just now</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-item">
                      <a
                        class="notification notification-flush notification-unread"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <div class="avatar-name rounded-circle">
                              <span>AB</span>
                            </div>
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>Albert Brooks</strong> reacted to
                            <strong>Mia Khalifa's</strong> status
                          </p>
                          <span class="notification-time"
                            ><span
                              class="me-2 fab fa-gratipay text-danger"
                            ></span
                            >9hr</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-title border-bottom">EARLIER</div>
                    <div class="list-group-item">
                      <a
                        class="notification notification-flush"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/icons/weather-sm.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            The forecast today shows a low of 20&#8451; in
                            California. See today's weather.
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >🌤️</span
                            >1d</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-item">
                      <a
                        class="border-bottom-0 notification-unread notification notification-flush"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/logos/oxford.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>University of Oxford</strong> created an
                            event : "Causal Inference Hilary 2019"
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >✌️</span
                            >1w</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-item">
                      <a
                        class="border-bottom-0 notification notification-flush"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/team/10.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>James Cameron</strong> invited to join the
                            group: United Nations International Children's Fund
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >🙋‍</span
                            >2d</span
                          >
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center border-top">
                  <a
                    class="card-link d-block"
                    href="../social/notifications.html"
                    >View all</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown px-1">
            <a
              class="nav-link fa-icon-wait nine-dots p-1"
              id="navbarDropdownMenu"
              role="button"
              data-hide-on-body-scroll="data-hide-on-body-scroll"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="43"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
                <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
                <circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
                <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
                <circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
                <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
                <circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
                <circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
                <circle cx="14" cy="2" r="2" fill="#6C6E71"></circle></svg
            ></a>
            <div
              class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-caret-bg"
              aria-labelledby="navbarDropdownMenu"
            >
              <div class="card shadow-none">
                <div class="scrollbar-overlay nine-dots-dropdown">
                  <div class="card-body px-3">
                    <div class="row text-center gx-0 gy-0">
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="../../pages/user/profile.html"
                          target="_blank"
                        >
                          <div class="avatar avatar-2xl">
                            <img
                              class="rounded-circle"
                              src="assets/img/team/3.jpg"
                              alt=""
                            />
                          </div>
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2"
                          >
                            Account
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="https://themewagon.com/"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/themewagon.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Themewagon
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="https://mailbluster.com/"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/mailbluster.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Mailbluster
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/google.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Google
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/spotify.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Spotify
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/steam.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Steam
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/github-light.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Github
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/discord.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Discord
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/xbox.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            xbox
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/trello.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Kanban
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/hp.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Hp
                          </p>
                        </a>
                      </div>
                      <div class="col-12">
                        <hr class="my-3 mx-n3 bg-200" />
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/linkedin.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Linkedin
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/twitter.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Twitter
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/facebook.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Facebook
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/instagram.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Instagram
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/pinterest.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Pinterest
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/slack.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Slack
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/deviantart.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Deviantart
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="../events/event-detail.html"
                          target="_blank"
                        >
                          <div class="avatar avatar-2xl">
                            <div
                              class="avatar-name rounded-circle bg-soft-primary text-primary"
                            >
                              <span class="fs-2">E</span>
                            </div>
                          </div>
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2"
                          >
                            Events
                          </p>
                        </a>
                      </div>
                      <div class="col-12">
                        <a
                          class="btn btn-outline-primary btn-sm mt-4"
                          href="invoice.html#!"
                          >Show more</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link pe-0 ps-2"
              id="navbarDropdownUser"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="avatar avatar-xl">
                <img
                  class="rounded-circle"
                  src="assets/img/team/3-thumb.png"
                  alt=""
                />
              </div>
            </a>
            <div
              class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
              aria-labelledby="navbarDropdownUser"
            >
              <div class="bg-white dark__bg-1000 rounded-2 py-2">
                <a
                  class="dropdown-item fw-bold text-warning"
                  href="invoice.html#!"
                  ><span class="fas fa-crown me-1"></span><span>Go Pro</span></a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="invoice.html#!">Set status</a>
                <a class="dropdown-item" href="../../pages/user/profile.html"
                  >Profile &amp; account</a
                >
                <a class="dropdown-item" href="invoice.html#!">Feedback</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="../../pages/user/settings.html"
                  >Settings</a
                >
                <a
                  class="dropdown-item"
                  href="../../pages/authentication/card/logout.html"
                  >Logout</a
                >
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <nav
        class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg"
        style="display: none"
        data-move-target="#navbarVerticalNav"
        data-navbar-top="combo"
      >
        <button
          class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarVerticalCollapse"
          aria-controls="navbarVerticalCollapse"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <span class="navbar-toggle-icon"
            ><span class="toggle-line"></span
          ></span>
        </button>
        <a class="navbar-brand me-1 me-sm-3" href="../../index.html">
          <div class="d-flex align-items-center">
            <img
              class="me-2"
              src="assets/img/icons/spot-illustrations/falcon.png"
              alt=""
              width="40"
            /><span class="font-sans-serif">falcon</span>
          </div>
        </a>
        <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
          <ul
            class="navbar-nav"
            data-top-nav-dropdowns="data-top-nav-dropdowns"
          >
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="dashboards"
                >Dashboard</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="dashboards"
              >
                <div class="bg-white dark__bg-1000 rounded-3 py-2">
                  <a
                    class="dropdown-item link-600 fw-medium"
                    href="../../index.html"
                    >Default</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/analytics.html"
                    >Analytics</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/crm.html"
                    >CRM</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/e-commerce.html"
                    >E commerce</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/lms.html"
                    >LMS<span class="badge rounded-pill ms-2 badge-soft-success"
                      >New</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/project-management.html"
                    >Management</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../dashboard/saas.html"
                    >SaaS</a
                  >
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="apps"
                >App</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="apps"
              >
                <div class="card navbar-card-app shadow-none dark__bg-1000">
                  <div class="card-body scrollbar max-h-dropdown">
                    <img
                      class="img-dropdown"
                      src="assets/img/icons/spot-illustrations/authentication-corner.png"
                      width="130"
                      alt=""
                    />
                    <div class="row">
                      <div class="col-6 col-md-5">
                        <div class="nav flex-column">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../calendar.html"
                            >Calendar</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../chat.html"
                            >Chat</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-list.html"
                            >Course list</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Email</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../email/inbox.html"
                            >Inbox</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../email/email-detail.html"
                            >Email detail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../email/compose.html"
                            >Compose</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Events</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../events/create-an-event.html"
                            >Create an event</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../events/event-detail.html"
                            >Event detail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../events/event-list.html"
                            >Event list</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">
                            E-Learning
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-list.html"
                            >Course list</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-grid.html"
                            >Course grid</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/course-details.html"
                            >Course details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/course/create-a-course.html"
                            >Create a course</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/student-overview.html"
                            >Student overview</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../e-learning/trainer-profile.html"
                            >Trainer profile</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-md-4">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            E-Commerce
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="product/product-list.html"
                            >Product list</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="product/product-grid.html"
                            >Product grid</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="product/product-details.html"
                            >Product details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="orders/order-list.html"
                            >Order list</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="orders/order-details.html"
                            >Order details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="customers.html"
                            >Customers</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="customer-details.html"
                            >Customer details</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="shopping-cart.html"
                            >Shopping cart</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="checkout.html"
                            >Checkout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="billing.html"
                            >Billing</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="invoice.html"
                            >Invoice</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Social</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/feed.html"
                            >Feed</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/activity-log.html"
                            >Activity log</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/notifications.html"
                            >Notifications</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../social/followers.html"
                            >Followers</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="pagess"
                >Pages</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="pagess"
              >
                <div class="card navbar-card-pages shadow-none dark__bg-1000">
                  <div class="card-body scrollbar max-h-dropdown">
                    <img
                      class="img-dropdown"
                      src="assets/img/icons/spot-illustrations/authentication-corner.png"
                      width="130"
                      alt=""
                    />
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Simple Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/login.html"
                            >Login</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/logout.html"
                            >Logout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/register.html"
                            >Register</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/forgot-password.html"
                            >Forgot password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/confirm-mail.html"
                            >Confirm mail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/reset-password.html"
                            >Reset password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/simple/lock-screen.html"
                            >Lock screen</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Card Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/login.html"
                            >Login</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/logout.html"
                            >Logout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/register.html"
                            >Register</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/forgot-password.html"
                            >Forgot password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/confirm-mail.html"
                            >Confirm mail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/reset-password.html"
                            >Reset password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/card/lock-screen.html"
                            >Lock screen</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Split Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/login.html"
                            >Login</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/logout.html"
                            >Logout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/register.html"
                            >Register</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/forgot-password.html"
                            >Forgot password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/confirm-mail.html"
                            >Confirm mail</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/reset-password.html"
                            >Reset password</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/split/lock-screen.html"
                            >Lock screen</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Layouts</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/navbar-vertical.html"
                            >Navbar vertical</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/navbar-top.html"
                            >Top nav</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/navbar-double-top.html"
                            >Double top<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../demo/combo-nav.html"
                            >Combo nav</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Others</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/starter.html"
                            >Starter</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/landing.html"
                            >Landing</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">User</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/user/profile.html"
                            >Profile</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/user/settings.html"
                            >Settings</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Pricing</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/pricing/pricing-default.html"
                            >Pricing default</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/pricing/pricing-alt.html"
                            >Pricing alt</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Errors</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/errors/404.html"
                            >404</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/errors/500.html"
                            >500</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Miscellaneous
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/miscellaneous/associations.html"
                            >Associations</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/miscellaneous/invite-people.html"
                            >Invite people</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/miscellaneous/privacy-policy.html"
                            >Privacy policy</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">FAQ</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/faq/faq-basic.html"
                            >Faq basic</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/faq/faq-alt.html"
                            >Faq alt</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/faq/faq-accordion.html"
                            >Faq accordion</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Other Auth
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../pages/authentication/wizard.html"
                            >Wizard</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../index.html#authentication-modal"
                            data-bs-toggle="modal"
                            >Modal</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="moduless"
                >Modules</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="moduless"
              >
                <div
                  class="card navbar-card-components shadow-none dark__bg-1000"
                >
                  <div class="card-body scrollbar max-h-dropdown">
                    <img
                      class="img-dropdown"
                      src="assets/img/icons/spot-illustrations/authentication-corner.png"
                      width="130"
                      alt=""
                    />
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Components
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/maps/leaflet-map.html"
                            >Leaflet map</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/accordion.html"
                            >Accordion</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/alerts.html"
                            >Alerts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/anchor.html"
                            >Anchor</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/animated-icons.html"
                            >Animated icons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/background.html"
                            >Background</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/badges.html"
                            >Badges</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/bottom-bar.html"
                            >Bottom bar<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/breadcrumbs.html"
                            >Breadcrumbs</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/buttons.html"
                            >Buttons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/calendar.html"
                            >Calendar</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/cards.html"
                            >Cards</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column mt-md-4 pt-md-1">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/carousel/bootstrap.html"
                            >Bootstrap carousel</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/carousel/swiper.html"
                            >Swiper</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/collapse.html"
                            >Collapse</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/cookie-notice.html"
                            >Cookie notice</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/countup.html"
                            >Countup</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/draggable.html"
                            >Draggable</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/dropdowns.html"
                            >Dropdowns</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/jquery-components.html"
                            >Jquery<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/list-group.html"
                            >List group</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/modals.html"
                            >Modals</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/navs.html"
                            >Navs</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/navbar.html"
                            >Navbar</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column mt-xxl-4 pt-xxl-1">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/vertical-navbar.html"
                            >Navbar vertical</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/top-navbar.html"
                            >Top nav</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/double-top-navbar.html"
                            >Double top<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/combo-navbar.html"
                            >Combo nav</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/navs-and-tabs/tabs.html"
                            >Tabs</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/offcanvas.html"
                            >Offcanvas</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/avatar.html"
                            >Avatar</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/images.html"
                            >Images</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/figures.html"
                            >Figures</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/hoverbox.html"
                            >Hoverbox</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pictures/lightbox.html"
                            >Lightbox</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/progress-bar.html"
                            >Progress bar</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column mt-xxl-4 pt-xxl-1">
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/placeholder.html"
                            >Placeholder</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/pagination.html"
                            >Pagination</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/popovers.html"
                            >Popovers</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/scrollspy.html"
                            >Scrollspy</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/search.html"
                            >Search</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/spinners.html"
                            >Spinners</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/timeline.html"
                            >Timeline</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/toasts.html"
                            >Toasts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/tooltips.html"
                            >Tooltips</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Forms</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/form-control.html"
                            >Form control</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/input-group.html"
                            >Input group</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/select.html"
                            >Select</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/checks.html"
                            >Checks</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/range.html"
                            >Range</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/basic/layout.html"
                            >Layout</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/advance-select.html"
                            >Advance select</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/date-picker.html"
                            >Date picker</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/editor.html"
                            >Editor</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/emoji-button.html"
                            >Emoji button</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/file-uploader.html"
                            >File uploader</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/advance/rating.html"
                            >Rating</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/floating-labels.html"
                            >Floating labels</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/wizard.html"
                            >Wizard</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/forms/validation.html"
                            >Validation</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">Tables</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/tables/basic-tables.html"
                            >Basic tables</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/tables/advance-tables.html"
                            >Advance tables</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/tables/bulk-select.html"
                            >Bulk select</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Charts</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/chartjs.html"
                            >Chartjs</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">ECharts</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/d3js.html"
                            >D3js<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/line-charts.html"
                            >Line charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/bar-charts.html"
                            >Bar charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/candlestick-charts.html"
                            >Candlestick charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/geo-map.html"
                            >Geo map</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/scatter-charts.html"
                            >Scatter charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/pie-charts.html"
                            >Pie charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/radar-charts.html"
                            >Radar charts</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/heatmap-charts.html"
                            >Heatmap charts</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column">
                          <p class="nav-link text-700 mb-0 fw-bold">
                            Utilities
                          </p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/treeview.html"
                            >Treeview</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/typed-text.html"
                            >Typed text</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/videos/embed.html"
                            >Embed</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/components/videos/plyr.html"
                            >Plyr</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/borders.html"
                            >Borders</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/clearfix.html"
                            >Clearfix</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/colors.html"
                            >Colors</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/colored-links.html"
                            >Colored links</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/display.html"
                            >Display</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/flex.html"
                            >Flex</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/float.html"
                            >Float</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/grid.html"
                            >Grid</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/overlayscrollbar.html"
                            >Overlay scrollbar<span
                              class="badge rounded-pill ms-2 badge-soft-success"
                              >New</span
                            ></a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/position.html"
                            >Position</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/utilities/spacing.html"
                            >Spacing</a
                          >
                        </div>
                      </div>
                      <div class="col-6 col-xxl-3">
                        <div class="nav flex-column pt-xxl-1">
                          <p class="nav-link text-700 mb-0 fw-bold">Icons</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/charts/echarts/how-to-use.html"
                            >How to use</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/font-awesome.html"
                            >Font awesome</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/bootstrap-icons.html"
                            >Bootstrap icons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/feather.html"
                            >Feather</a
                          >
                          <p class="nav-link text-700 mb-0 fw-bold">Maps</p>
                          <a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/icons/material-icons.html"
                            >Material icons</a
                          ><a
                            class="nav-link py-1 link-600 fw-medium"
                            href="../../modules/maps/google-map.html"
                            >Google map</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="invoice.html#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="documentations"
                >Documentation</a
              >
              <div
                class="dropdown-menu dropdown-caret dropdown-menu-card border-0 mt-0"
                aria-labelledby="documentations"
              >
                <div class="bg-white dark__bg-1000 rounded-3 py-2">
                  <a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/getting-started.html"
                    >Getting started</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/configuration.html"
                    >Configuration</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/styling.html"
                    >Styling<span
                      class="badge rounded-pill ms-2 badge-soft-success"
                      >Updated</span
                    ></a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/dark-mode.html"
                    >Dark mode</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/customization/plugin.html"
                    >Plugin</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/faq.html"
                    >Faq</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/gulp.html"
                    >Gulp</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../documentation/design-file.html"
                    >Design file</a
                  ><a
                    class="dropdown-item link-600 fw-medium"
                    href="../../changelog.html"
                    >Changelog</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ul
          class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center"
        >
          <li class="nav-item">
            <div class="theme-control-toggle fa-icon-wait px-2">
              <input
                class="form-check-input ms-0 theme-control-toggle-input"
                id="themeControlToggle"
                type="checkbox"
                data-theme-control="theme"
                value="dark"
              /><label
                class="mb-0 theme-control-toggle-label theme-control-toggle-light"
                for="themeControlToggle"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Switch to light theme"
                ><span class="fas fa-sun fs-0"></span></label
              ><label
                class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                for="themeControlToggle"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Switch to dark theme"
                ><span class="fas fa-moon fs-0"></span
              ></label>
            </div>
          </li>
          <li class="nav-item d-none d-sm-block">
            <a
              class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill fa-icon-wait"
              href="shopping-cart.html"
              ><span
                class="fas fa-shopping-cart"
                data-fa-transform="shrink-7"
                style="font-size: 33px"
              ></span
              ><span class="notification-indicator-number">1</span></a
            >
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link notification-indicator notification-indicator-primary px-0 fa-icon-wait"
              id="navbarDropdownNotification"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-hide-on-body-scroll="data-hide-on-body-scroll"
              ><span
                class="fas fa-bell"
                data-fa-transform="shrink-6"
                style="font-size: 33px"
              ></span
            ></a>
            <div
              class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
              aria-labelledby="navbarDropdownNotification"
            >
              <div class="card card-notification shadow-none">
                <div class="card-header">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto">
                      <h6 class="card-header-title mb-0">Notifications</h6>
                    </div>
                    <div class="col-auto ps-0 ps-sm-3">
                      <a class="card-link fw-normal" href="invoice.html#"
                        >Mark all as read</a
                      >
                    </div>
                  </div>
                </div>
                <div class="scrollbar-overlay" style="max-height: 19rem">
                  <div class="list-group list-group-flush fw-normal fs--1">
                    <div class="list-group-title border-bottom">NEW</div>
                    <div class="list-group-item">
                      <a
                        class="notification notification-flush notification-unread"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/team/1-thumb.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>Emma Watson</strong> replied to your comment
                            : "Hello world 😍"
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >💬</span
                            >Just now</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-item">
                      <a
                        class="notification notification-flush notification-unread"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <div class="avatar-name rounded-circle">
                              <span>AB</span>
                            </div>
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>Albert Brooks</strong> reacted to
                            <strong>Mia Khalifa's</strong> status
                          </p>
                          <span class="notification-time"
                            ><span
                              class="me-2 fab fa-gratipay text-danger"
                            ></span
                            >9hr</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-title border-bottom">EARLIER</div>
                    <div class="list-group-item">
                      <a
                        class="notification notification-flush"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/icons/weather-sm.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            The forecast today shows a low of 20&#8451; in
                            California. See today's weather.
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >🌤️</span
                            >1d</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-item">
                      <a
                        class="border-bottom-0 notification-unread notification notification-flush"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/logos/oxford.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>University of Oxford</strong> created an
                            event : "Causal Inference Hilary 2019"
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >✌️</span
                            >1w</span
                          >
                        </div>
                      </a>
                    </div>
                    <div class="list-group-item">
                      <a
                        class="border-bottom-0 notification notification-flush"
                        href="invoice.html#!"
                      >
                        <div class="notification-avatar">
                          <div class="avatar avatar-xl me-3">
                            <img
                              class="rounded-circle"
                              src="assets/img/team/10.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">
                            <strong>James Cameron</strong> invited to join the
                            group: United Nations International Children's Fund
                          </p>
                          <span class="notification-time"
                            ><span class="me-2" role="img" aria-label="Emoji"
                              >🙋‍</span
                            >2d</span
                          >
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center border-top">
                  <a
                    class="card-link d-block"
                    href="../social/notifications.html"
                    >View all</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown px-1">
            <a
              class="nav-link fa-icon-wait nine-dots p-1"
              id="navbarDropdownMenu"
              role="button"
              data-hide-on-body-scroll="data-hide-on-body-scroll"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="43"
                viewBox="0 0 16 16"
                fill="none"
              >
                <circle cx="2" cy="2" r="2" fill="#6C6E71"></circle>
                <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
                <circle cx="2" cy="14" r="2" fill="#6C6E71"></circle>
                <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
                <circle cx="8" cy="14" r="2" fill="#6C6E71"></circle>
                <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
                <circle cx="14" cy="14" r="2" fill="#6C6E71"></circle>
                <circle cx="8" cy="2" r="2" fill="#6C6E71"></circle>
                <circle cx="14" cy="2" r="2" fill="#6C6E71"></circle></svg
            ></a>
            <div
              class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end dropdown-menu-card dropdown-caret-bg"
              aria-labelledby="navbarDropdownMenu"
            >
              <div class="card shadow-none">
                <div class="scrollbar-overlay nine-dots-dropdown">
                  <div class="card-body px-3">
                    <div class="row text-center gx-0 gy-0">
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="../../pages/user/profile.html"
                          target="_blank"
                        >
                          <div class="avatar avatar-2xl">
                            <img
                              class="rounded-circle"
                              src="assets/img/team/3.jpg"
                              alt=""
                            />
                          </div>
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2"
                          >
                            Account
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="https://themewagon.com/"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/themewagon.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Themewagon
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="https://mailbluster.com/"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/mailbluster.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Mailbluster
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/google.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Google
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/spotify.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Spotify
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/steam.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Steam
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/github-light.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Github
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/discord.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Discord
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/xbox.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            xbox
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/trello.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Kanban
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/hp.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Hp
                          </p>
                        </a>
                      </div>
                      <div class="col-12">
                        <hr class="my-3 mx-n3 bg-200" />
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/linkedin.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Linkedin
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/twitter.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Twitter
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/facebook.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Facebook
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/instagram.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Instagram
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/pinterest.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Pinterest
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/slack.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Slack
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="invoice.html#!"
                          target="_blank"
                          ><img
                            class="rounded"
                            src="assets/img/nav-icons/deviantart.png"
                            alt=""
                            width="40"
                            height="40"
                          />
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2 pt-1"
                          >
                            Deviantart
                          </p>
                        </a>
                      </div>
                      <div class="col-4">
                        <a
                          class="d-block hover-bg-200 px-2 py-3 rounded-3 text-center text-decoration-none"
                          href="../events/event-detail.html"
                          target="_blank"
                        >
                          <div class="avatar avatar-2xl">
                            <div
                              class="avatar-name rounded-circle bg-soft-primary text-primary"
                            >
                              <span class="fs-2">E</span>
                            </div>
                          </div>
                          <p
                            class="mb-0 fw-medium text-800 text-truncate fs--2"
                          >
                            Events
                          </p>
                        </a>
                      </div>
                      <div class="col-12">
                        <a
                          class="btn btn-outline-primary btn-sm mt-4"
                          href="invoice.html#!"
                          >Show more</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link pe-0 ps-2"
              id="navbarDropdownUser"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="avatar avatar-xl">
                <img
                  class="rounded-circle"
                  src="assets/img/team/3-thumb.png"
                  alt=""
                />
              </div>
            </a>
            <div
              class="dropdown-menu dropdown-caret dropdown-caret dropdown-menu-end py-0"
              aria-labelledby="navbarDropdownUser"
            >
              <div class="bg-white dark__bg-1000 rounded-2 py-2">
                <a
                  class="dropdown-item fw-bold text-warning"
                  href="invoice.html#!"
                  ><span class="fas fa-crown me-1"></span><span>Go Pro</span></a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="invoice.html#!">Set status</a>
                <a class="dropdown-item" href="../../pages/user/profile.html"
                  >Profile &amp; account</a
                >
                <a class="dropdown-item" href="invoice.html#!">Feedback</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="../../pages/user/settings.html"
                  >Settings</a
                >
                <a
                  class="dropdown-item"
                  href="../../pages/authentication/card/logout.html"
                  >Logout</a
                >
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <h5 class="mb-2 mb-md-0">Order #AD20294</h5>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                type="button"
              >
                <span class="fas fa-arrow-down me-1"> </span>Download (.pdf)</button
              ><button
                class="btn btn-falcon-default btn-sm me-1 mb-2 mb-sm-0"
                type="button"
              >
                <span class="fas fa-print me-1"> </span>Print</button
              ><button
                class="btn btn-falcon-success btn-sm mb-2 mb-sm-0"
                type="button"
              >
                <span class="fas fa-dollar-sign me-1"></span>Receive Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="notepad-area" class="card mb-3">
        <div class="card-body">
          <textarea class="mt-sm-1 fs-sm-1 mx-sm-7" />
        </div>
        <div class="card-footer bg-light">
          <p class="fs--1 mb-0">
            <strong>Notes: </strong>We really appreciate your business and if
            there’s anything else we can do, please let us know!
          </p>
        </div>
      </div>
      <footer class="footer">
        <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">
              Thank you for creating with Falcon
              <span class="d-none d-sm-inline-block">| </span
              ><br class="d-sm-none" />
              2022 &copy; <a href="https://themewagon.com">Themewagon</a>
            </p>
          </div>
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">v3.13.0</p>
          </div>
        </div>
      </footer>
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="invoice.html#!">terms </a>and
                  <a href="invoice.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="invoice.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="invoice.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#notepad-area {
  background-image: url("https://bulla.co.ke/bulla-notepad-bg.png");
  /* make a square container */
  width: 100%;
  height: 100%;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;
}
textarea {
    background: transparent;
    width: 90%;
    border:0 none;
    outline: none;
    height: 1000px;
    position: relative;
  }
  
  /*border:0 none; */
</style>

<script>
import $ from "jquery";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import ContactService from "../services/contact.service";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
  name: "HomeView",
  components: {},
  setup() {
    return { v$: useVuelidate() };
  },
  created() {
    this.forceReload();
  },
  data() {
    return {
      formData: {},
      email_or_phone: "",
      selected_option: 1,
    };
  },
  validations() {
    return {
      formData: {
        email: { required, email },
        phone: { required },
        name: { required },
        description: { required },
      },
    };
  },
  methods: {
    forceReload() {
      if (localStorage.getItem("reloaded")) {
        localStorage.removeItem("reloaded");
        this.$store.commit("SET_LOADING", false);
      } else {
        this.$store.commit("SET_LOADING", false);
        localStorage.setItem("reloaded", "1");
        this.$router.go();
      }
    },
    selectedOption(option) {
      if (option == 1) {
        $("#demoRequestModal").hide();
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        window.open("https://ins.bulla.co.ke/", "_blank");
      } else {
        $("#demoRequestModal").hide();
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        window.open("https://demo.bulla.co.ke/", "_blank");
      }
    },
    startSubscription() {
      this.$router.push("/get-started");
    },
    phoneUpdated(e) {
      this.v$.formData.phone.$touch();
      console.log(e.target.value);
      this.formData.phone = e.target.value;
    },
    saveContact() {
      this.v$.formData.email.$touch();
      this.v$.formData.phone.$touch();
      this.v$.formData.name.$touch();
      this.v$.formData.description.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.formData.note = this.formData.description;
      this.formData.mobile_no = this.formData.phone;
      this.formData.first_name = this.formData.name.split(" ")[0];
      this.formData.last_name = this.formData.name;
      this.$store.commit("SET_LOADING", true);
      ContactService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            // Clear modals
            $("#request-demo-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            toast.success("Your details have been saved!");
          } else {
            console.log(response.data.error);
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.success(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
